import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

import actions from './actions'
import appActions from '../app/actions'

import {
  delay,
  getEventTotalSum,
  getPackageListForUpgrade,
  listTransactionsAPI,
} from '../../utils/api'
import ModalHandler from '../../utils/modal'

export function* listTransaction() {
  yield takeEvery(actions.ON_LIST_TRANSACTION, function*(action) {
    // yield console.log(action)
    const res = yield call(
      listTransactionsAPI,
      action.payload.id,
      action.payload.search
    )
    const res2 = yield call(getEventTotalSum, action.payload.id)
    const res3 = yield call(getPackageListForUpgrade, action.payload.id)
    yield call(delay, 300)
    if (res.code === 'OK') {
      yield put({
        type: actions.SET_LIST_TRANSACTION,
        payload: {
          transactionList: res.data,
          totalSummary: res2.data,
        },
      })
      if (res3.code === 'OK') {
        yield put({
          type: appActions.SET_PACKAGE_LIST_UPGRADE,
          payload: res3.data.packages || [],
        })
      }
      yield action.payload.callback()
    }
  })
}

export function* onShowTransactionDetail() {
  yield takeEvery(actions.ON_SHOW_TRANSACTION_DETAIL, function*(action) {
    // yield console.log(action)
    yield put({
      type: actions.SET_TRANSACTION_DETAIL,
      payload: action.payload,
    })

    yield ModalHandler.openModal('modalTransactionDetail')
  })
}

export default function* rootSaga() {
  yield all([fork(listTransaction), fork(onShowTransactionDetail)])
}
