import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import Cookies from 'js-cookie'
import { navigate } from 'gatsby-link'
import swal from 'sweetalert'

import ModalHandler from '../../utils/modal'
import actions from './actions'

import {
  delay,
  deleteEventBannerImageAPI,
  deleteEventImageAPI,
  deleteEventPlaceImageAPI,
  getBanner,
  getEventDetail,
  getEventDetailInvitation,
  getListEvents,
  getListPackages,
  getListPackagesForCreate,
  getPackageDetail,
  getProfile,
  getRepliesEvent,
  getTestimonials,
  postBlessing,
  postCheckIn,
  updateEventDetailAPI,
  acceptInvitation,
  getGoodsByEventID,
  getDeliveryByEventID,
  getListEventsNew,
} from '../../utils/api'
import { notificationWarning } from '../../utils/notification'
import { convertDateTimeZ, dateDiff } from '../../utils/string'

const getLang = function* () {
  const app = yield select(state => state.app)
  return {
    lang: app.lang,
    locale: app.locale[app.lang],
  }
}

export function* onLoad() {
  yield takeEvery(actions.ON_LOAD, function* (action) {
    const { lang } = yield call(getLang)
    const bannerData = yield call(getBanner, lang)
    const listEvent = yield call(getListEvents, 'public')
    const listPackages = yield call(getListPackages)
    const listTestimonials = yield call(getTestimonials)

    yield call(delay, 300)

    let listPackagesData = {
      data_head: [],
      data_list: [],
    }
    if (listPackages.code === 'OK') {
      listPackagesData = listPackages.data.packages
    }

    yield put({
      type: actions.SET_HOME_DATA,
      payload: {
        bannerData: bannerData.data.banners,
        listEvent: listEvent.data.events,
        listPackages: listPackagesData,
        listTestimonials: listTestimonials.data.testimonials,
      },
    })
  })
}

export function* onLoadHomeLogin() {
  yield takeEvery(actions.ON_LOAD_HOME, function* (action) {
    const { lang } = yield call(getLang)
    const bannerData = yield call(getBanner, lang)
    const listUpcoming = yield call(getListEvents, 'upcoming')
    const listJoined = yield call(getListEvents, 'joined')
    const listHistory = yield call(getListEvents, 'history')
    const listPublic = yield call(getListEvents, 'public')
    yield call(delay, 300)
    yield put({
      type: actions.SET_HOME_LOGIN_DATA,
      payload: {
        bannerData: bannerData.data.banners,
        listUpcoming: listUpcoming.data.events,
        listJoined: listJoined.data.events,
        listHistory: listHistory.data.events,
        listPublic: listPublic.data.events,
      },
    })
  })
}

export function* onLoadClubActivity() {
  yield takeEvery(actions.ON_LOAD_MY_CLUB_ACTIVITY, function* (action) {
    const { lang } = yield call(getLang)
    //const listUpcoming = yield call(getListEvents, 'upcoming')
    //const listJoined = yield call(getListEvents, 'joined')
    //const listHistory = yield call(getListEvents, 'history')
    //const listPublic = yield call(getListEvents, 'public')
    const listClub = yield call(getListEvents, 'club')
    const inClubHistory = yield call(getListEvents, 'history')
    const inClubUpcoming = yield call(getListEvents, 'upcoming')
    const bannerData = yield call(getBanner, lang)
    yield call(delay, 300)
    yield put({
      type: actions.SET_HOME_LOGIN_DATA,
      payload: {
        bannerData: bannerData.data.banners,
        //listUpcoming: listUpcoming.data.events,
        //listJoined: listJoined.data.events,
        //listHistory: listHistory.data.events,
        //listPublic: listPublic.data.events,
        listClub: listClub.data.events || [],
        inClubHistory: inClubHistory.data.events || [],
        inClubUpcoming: inClubUpcoming.data.events || [],
      },
    })
  })
}

export function* onLoadMyEvents() {
  yield takeEvery(actions.ON_LOAD_MY_EVENT, function* (action) {
    const listMyEvents = yield call(getListEvents, 'owned')
    const listPackageCreateNewEvents = yield call(getListPackagesForCreate)
    yield call(delay, 300)
    yield put({
      type: actions.SET_MY_EVENTS_DATA,
      payload: {
        listPackageCreateNewEvents: listPackageCreateNewEvents.data.packages,
        listMyEvents: listMyEvents.data.events,
      },
    })
  })
}

export function* onLoadUpcoming() {
  yield takeEvery(actions.ON_LOAD_UPCOMING, function* (action) {
    const listUpcomingAll = yield call(getListEvents, 'upcoming')
    yield call(delay, 300)
    yield put({
      type: actions.SET_UPCOMING_DATA,
      payload: {
        listUpcomingAll: listUpcomingAll.data.events,
      },
    })
  })
}

export function* onLoadMyEventALl() {
  yield takeEvery(actions.ON_LOAD_MY_EVENT_ALL, function* (action) {
    const listData = yield call(getListEvents, 'owned')
    yield call(delay, 300)
    yield put({
      type: actions.SET_MY_EVENT_ALL_DATA,
      payload: {
        listData: listData.data.events,
      },
    })
  })
}

export function* onLoadJoin() {
  yield takeEvery(actions.ON_LOAD_JOIN, function* (action) {
    const listJoinedAll = yield call(getListEvents, 'joined')
    yield call(delay, 300)
    yield put({
      type: actions.SET_JOIN_DATA,
      payload: {
        listJoinedAll: listJoinedAll.data.events,
      },
    })
  })
}

export function* onLoadHistory() {
  yield takeEvery(actions.ON_LOAD_HISTORY, function* (action) {
    const listHistoryAll = yield call(getListEvents, 'history')
    yield call(delay, 300)
    yield put({
      type: actions.SET_HISTORY_DATA,
      payload: {
        listHistoryAll: listHistoryAll.data.events,
      },
    })
  })
}

export function* onLoadPublic() {
  yield takeEvery(actions.ON_LOAD_PUBLIC, function* (action) {
    const listPublicAll = yield call(getListEvents, 'public')
    yield call(delay, 300)
    yield put({
      type: actions.SET_PUBLIC_DATA,
      payload: {
        listPublicAll: listPublicAll.data.events,
      },
    })
  })
}

export function* onLoadSearch() {
  yield takeEvery(actions.ON_LOAD_SEARCH, function* (action) {
    if (action.payload) {
      let filter = ''
      if (action.payload.search) {
        filter = `&filter=${action.payload.search.keyword}`
      }
      const listUpcoming = yield call(getListEvents, 'upcoming', filter)
      const listJoined = yield call(getListEvents, 'joined', filter)
      const listHistory = yield call(getListEvents, 'history', filter)
      const listPublic = yield call(getListEvents, 'public', filter)
      yield call(delay, 300)
      yield put({
        type: actions.SET_HOME_LOGIN_DATA,
        payload: {
          bannerData: [],
          listUpcoming: listUpcoming.data.events,
          listJoined: listJoined.data.events,
          listHistory: listHistory.data.events,
          listPublic: listPublic.data.events,
        },
      })
      action.payload.cb()
    } else {
      yield put({
        type: actions.SET_SEARCH_LOAD,
      })
    }
  })
}

export function* onLoadRepilesEvent() {
  yield takeEvery(actions.ON_LOAD_REPILES_EVENT, function* (action) {
    const { eventID, limit, offset } = action.payload
    const dataReplies = yield call(getRepliesEvent, eventID, limit, offset)
    if (dataReplies.code === 'OK') {
      let invitations = dataReplies.data.invitations
      if (invitations && invitations.length > 0) {
        yield put({
          type: actions.SET_ASSIGN_REPLY_MESSAGE_MORE,
          payload: invitations || [],
        })
      }
    }
  })
}

export function* onLoadDetail() {
  yield takeEvery(actions.ON_LOAD_DETAIL, function* (action) {
    const { locale } = yield call(getLang)
    try {
      const data = yield call(
        getEventDetail,
        action.id,
        action.invitation_id,
        action.invitation_pin
      )
      const resPackage = yield call(
        getPackageDetail,
        data.data.original.package_id
      )
      const data2 =
        action.flag === 'viewDetail'
          ? yield call(getEventDetailInvitation, action.id)
          : { code: null }
      const dataReplies =
        action.flag === 'viewDetail'
          ? yield call(getRepliesEvent, action.id, 10, 0)
          : { code: null }
      const dataProfile =
        action.flag === 'viewDetail' ? yield call(getProfile) : { code: null }
      let allow_payment = resPackage.data.allow_payment
      let isPayment = 'NOT_ALLOW'
      let invitationDetail = {}
      let repliesList = []

      if (data2.code === 'OK') {
        if (data2.data) {
          invitationDetail = { ...data2.data }
          if (data2.data.status) {
            isPayment = data2.data.status
          }
        }
      }
      if (dataReplies.code === 'OK') {
        repliesList = dataReplies.data.invitations || []
      }

      let profileData = {}
      if (dataProfile.code === 'OK') {
        profileData = dataProfile.data
      }
      // console.log(action, data)
      yield call(delay, 300)
      yield put({
        type: actions.SET_EVENT_DETAIL_DATA,
        payload: {
          eventDetail: {
            ...data.data.result,
            allow_payment,
            isPayment,
            repliesList,
            invitationDetail,
            profileData,
          },
          eventDetailOriginal: {
            ...data.data.original,
            allow_payment,
            isPayment,
            repliesList,
            invitationDetail,
            profileData,
          },
        },
      })
      if (action.flag === 'viewDetail') {
        if (data.data.original.last_payment_date) {
          const diff = dateDiff(
            convertDateTimeZ(data.data.original.last_payment_date)
          )
          if (diff < 0) {
            yield notificationWarning(
              locale.WARNING,
              locale.WARNING_EXPIRE_JOIN_EVENT
            )
            yield put({
              type: actions.SET_EXPIRE,
              payload: true,
            })
          } else {
            yield put({
              type: actions.SET_EXPIRE,
              payload: false,
            })
          }
        } else {
          yield put({
            type: actions.SET_EXPIRE,
            payload: false,
          })
        }
        if (invitationDetail.id) {
          if (
            invitationDetail.reply_message === '' &&
            ['checked-in', 'joined'].indexOf(invitationDetail.status) > -1
          ) {
            yield notificationWarning(
              locale.WARNING,
              locale.WARNING_PLEASE_REPLY_MESSAGE
            )
            // yield swal(locale.WARNING, locale.WARNING_PLEASE_REPLY_MESSAGE, 'warning')
          }
        }
      }
    } catch (error) {
      swal(locale.ERROR, locale.JOIN_INVITATION_FAIL, 'error').then(
        navigate(`/`)
      )
    }
  })
}

export function* onLoadGoodsDelivery() {
  const { locale } = yield call(getLang)
  yield takeEvery(actions.GET_GOODS_DELIVERY_BY_EVENT, function* (action) {
    try {
      const goods = yield call(getGoodsByEventID, action.eventID)
      const delivery = yield call(getDeliveryByEventID, action.eventID)
      if ((goods.code === 'OK') && (delivery.code === 'OK')) {
        yield put({
          type: actions.SET_GOODS_DELIVERY_BY_EVENT,
          payload: {
            goods: goods.data,
            delivery: delivery.data
          },
        })
        
        const returnData = {
          goods: goods.data,
          delivery: delivery.data
        }
        if (action.callback) {
          action.callback(returnData, null)
        }
      }
    } catch (error) {
      swal(locale.ERROR, locale.JOIN_INVITATION_FAIL, 'error').then(
        navigate(`/`)
      )
    }
  })
}

export function* onDetailSubmit() {
  yield takeEvery(actions.ON_DETAIL_SUMBIT, function* (action) {
    // yield console.log('ON_DETAIL_SUMBIT', action)
    const { locale } = yield call(getLang)
    try {
      const data = yield call(
        updateEventDetailAPI,
        action.payload.id,
        action.payload
      )
      if (data.code === 'OK') {
        yield put({
          type: actions.SET_EVENT_DETAIL_ORIGINAL_DATA,
          payload: {
            eventDetailOriginal: action.payload,
          },
        })
        yield swal(locale.SUCCESS, locale.SAVE_SUCCESS, 'success')
      } else {
        yield swal(locale.ERROR, data.text, 'error')
      }
    } catch (e) {
      yield swal(locale.ERROR, e.error, 'error')
    }
  })
}

export function* onDeletePhotoGallery() {
  yield takeEvery(actions.ON_DETAIL_DELETE_IMAGE_GALLERY, function* (action) {
    // yield console.log(action)
    const { locale } = yield call(getLang)
    // eslint-disable-next-line no-restricted-globals
    const a = yield confirm(locale.WARNING_WANT_DELETE + '?')
    if (a) {
      const res = yield call(deleteEventImageAPI, action.id, action.fname)
      if (res.code === 'OK') {
        yield put({
          type: actions.DELETE_PHOTO_GALLERY,
          payload: {
            index1: action.index1,
            index2: action.index2,
            index3: action.index3,
          },
        })
        yield swal(locale.SUCCESS, locale.DELETE_SUCCESS, 'success')
      } else {
        yield swal(locale.ERROR, locale.DELETE_FAIL, 'error')
      }
    }
  })
}

export function* onDeleteDetailImageCover() {
  yield takeEvery(actions.ON_DETAIL_DELETE_IMAGE_COVER, function* (action) {
    // yield console.log(action)
    const { locale } = yield call(getLang)
    // eslint-disable-next-line no-restricted-globals
    const a = yield confirm(locale.WARNING_WANT_DELETE + '?')
    if (a) {
      const res = yield call(deleteEventBannerImageAPI, action.id)
      if (res.code === 'OK') {
        yield put({
          type: actions.DELETE_IMAGE_COVER,
        })
        yield swal(locale.SUCCESS, locale.DELETE_SUCCESS, 'success')
      } else {
        yield swal(locale.ERROR, locale.DELETE_FAIL, 'error')
      }
    }
  })
}

export function* onChangeImageCover() {
  yield takeEvery(actions.ON_CHANGE_EVENT_DETAIL_IMAGE_COVER, function* (
    action
  ) {
    // yield console.log(action)
    // eslint-disable-next-line no-restricted-globals
    yield put({
      type: actions.SET_EVENT_DETAIL_IMAGE_COVER,
      data: action.data,
    })
  })
}

export function* onAddPhotoGallery() {
  yield takeEvery(actions.ON_ADD_PHOTO_GALLERY, function* (action) {
    // yield console.log(action)
    // eslint-disable-next-line no-restricted-globals
    // console.log(action)
    yield put({
      type: actions.ADD_PHOTO_GALLERY,
      data: action.data,
    })
  })
}

export function* onAddLogoImage() {
  yield takeEvery(actions.ON_ADD_LOGO_IMAGE, function* (action) {
    // yield console.log(action)
    // eslint-disable-next-line no-restricted-globals
    // console.log(action)
    yield put({
      type: actions.ADD_LOGO_IMAGE,
      data: action.data,
    })
  })
}

export function* onDeleteDetailImagePlace() {
  yield takeEvery(actions.ON_DETAIL_DELETE_IMAGE_PLACE, function* (action) {
    // yield console.log(action)
    const { locale } = yield call(getLang)
    // eslint-disable-next-line no-restricted-globals
    const a = yield confirm(locale.WARNING_WANT_DELETE + '?')
    if (a) {
      const res = yield call(deleteEventPlaceImageAPI, action.id)
      if (res.code === 'OK') {
        yield put({
          type: actions.DELETE_IMAGE_PLACE_COVER,
        })
        yield swal(locale.SUCCESS, locale.DELETE_SUCCESS, 'success')
      } else {
        yield swal(locale.ERROR, locale.DELETE_FAIL, 'error')
      }
    }
  })
}

export function* onChooseEvent() {
  yield takeEvery(actions.ON_CHOOSE_MY_EVENT, function* (action) {
    let eventID = ''
    if (action.eventID && action.eventID !== '') {
      eventID = `&event_id=${action.eventID}`
    }
    // yield console.log(action)
    if (action.typeBuy === 'buy_ticket') {
      const event = yield select(state => state.home)
      if (event.eventDetailOriginal.join_type === 'free') {
        yield ModalHandler.openModal('modalConfirmJoinFree')
      } else {
        const join_type = `&join_type=${event.eventDetailOriginal.join_type}`
        yield navigate(
          `/buy-ticket/?package_id=${action.id}${join_type}&type=${action.typeBuy
          }${eventID}`
        )
      }
    } else {
      yield put({
        type: actions.SET_BUY_PACKAGE_DETAIL,
        payload: {
          id: action.id,
          typeBuy: action.typeBuy,
          eventID: action.eventID,
          joinType: action.joinType,
        },
      })
      let { joinType } = action
      let navigateToPayment = `/payment/?package_id=${action.id}&type=${action.typeBuy
        }${eventID}`

      if (joinType) {
        yield navigate(navigateToPayment + `&join_type=${joinType}`)
      } else {
        yield navigate(navigateToPayment)
      }
    }
  })
}

export function* onChooseEventForFree() {
  yield takeEvery(actions.ON_CHOOSE_MY_EVENT_FOR_FREE, function* (action) {
    let eventID = ''
    if (action.eventID) {
      eventID = `&event_id=${action.eventID}`
    }
    if (action.typeBuy === 'buy_ticket') {
      const event = yield select(state => state.home)
      const join_type = `&join_type=${event.eventDetailOriginal.join_type}`
      yield navigate(
        `/buy-ticket/?package_id=${action.id}${join_type}&type=${action.typeBuy
        }${eventID}`
      )
    } else {
      yield navigate(
        `/payment/?package_id=${action.id}&type=${action.typeBuy}${eventID}`
      )
    }
  })
}

export function* onEditEvent() {
  yield takeEvery(actions.ON_EDIT_MY_EVENT, function* (action) {
    // yield console.log(action)
    yield navigate('/event-detail/edit/?id=' + action.id)
  })
}

export function* onManageEvent() {
  yield takeEvery(actions.ON_MANAGE_MY_EVENT, function* (action) {
    // yield console.log(action)
    yield navigate('/invitations/?id=' + action.id)
  })
}

export function* onShare() {
  yield takeEvery(actions.ON_SHARE, function* (action) {
    yield put({
      type: actions.SET_SHARE_ID,
      id: action.id,
    })
    yield ModalHandler.openModal('modalShare')
    //https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fsachinchoolur.github.io%2Flightgallery.js%2F%23lg%3D1%26slide%3D2
    //https://twitter.com/intent/tweet?text=share it on twitter&url=https%3A%2F%2Fsachinchoolur.github.io%2Flightgallery.js%2F%23lg%3D1%26slide%3D2
    //https://plus.google.com/share?url=https%3A%2F%2Fsachinchoolur.github.io%2Flightgallery.js%2F%23lg%3D1%26slide%3D2
    //http://www.pinterest.com/pin/create/button/?url=https%3A%2F%2Fsachinchoolur.github.io%2Flightgallery.js%2F%23lg%3D1%26slide%3D2&media=static%2Fimg%2F13-1600.jpg&description=Pin it
  })
}

export function* onAddCalendar() {
  yield takeEvery(actions.ON_ADD_CALENDAR, function* (action) {
    const event = yield select(state => state.home)
    const lang = yield select(state => state.app.lang)
    const text =
      lang === 'TH'
        ? event.eventDetailOriginal.title_th
        : event.eventDetailOriginal.title_en
    const dates = event.eventDetailOriginal.date
      .replace(/-/g, '')
      .replace(/:/g, '')
    const details = 'Invitree Event'
    const location =
      lang === 'TH'
        ? event.eventDetailOriginal.place.address_th
        : event.eventDetailOriginal.place.address_en
    if (action.platform === 'GOOGLE') {
      const urlGoogle = `https://www.google.com/calendar/render?action=TEMPLATE&text=${text}&dates=${dates}/${dates}&details=${details}&location=${location}&sf=true&output=xml`
      window.open(urlGoogle, '_blank')
    } else if (action.platform === 'APPLE') {
      const icsMSG =
        'BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-//Our Company//NONSGML v1.0//EN\nBEGIN:VEVENT\nUID:me@gmail.com\nDTSTAMP:20120315T170000Z\nATTENDEE;CN=My Self ;RSVP=TRUE:MAILTO:me@gmail.com\nORGANIZER;CN=Me:MAILTO::me@gmail.com\nDTSTART:' +
        dates +
        '\nDTEND:' +
        dates +
        '\nLOCATION:' +
        location +
        '\nSUMMARY:' +
        event.eventDetailOriginal.title_en +
        '\nEND:VEVENT\nEND:VCALENDAR'
      window.open('data:text/calendar;charset=utf8,' + escape(icsMSG), '_blank')
    }
  })
}

export function* onJoinEvent() {
  yield takeEvery(actions.ON_JOIN_EVENT, function* (action) {
    yield ModalHandler.openModal('modalSignIn')
    Cookies.set('tempJoinEventID', action.id)
    yield console.log('ON_JOIN_EVENT', action)
  })
}

export function* onViewYourPass() {
  yield takeEvery(actions.ON_VIEW_YOUR_PASS, function* (action) {
    // yield console.log('ON_VIEW_YOUR_PASS', action)
    if (action.invitation_id !== 0) {
      yield ModalHandler.openModal('modalViewYourPass')
    } else {
      const { locale } = yield call(getLang)
      yield swal(locale.ERROR, locale.WARNING_INVITATION_ID_MISSING, 'error')
    }
  })
}

export function* onGoDetail() {
  yield takeEvery(actions.ON_DETAIL, function* (action) {
    yield navigate('/event-detail/?id=' + action.id)
  })
}

export function* onJoinFree() {
  yield takeEvery(actions.ON_JOIN, function* (action) {
    yield ModalHandler.openModal('modalSignIn')
  })
}

export function* onSubmitReplyMessage() {
  yield takeEvery(actions.SUBMIT_REPLY_MESSAGE, function* (action) {
    // yield console.log(action)
    const { locale } = yield call(getLang)
    const res = yield call(
      postBlessing,
      action.payload.message,
      action.payload.id
    )
    if (res.code === 'OK') {
      yield swal(locale.SUCCESS, locale.SEND_MESSAGE_SUCCESS, 'success')
      yield put({
        type: actions.SET_NEW_REPLY_MESSAGE,
        reply_message: action.payload.message,
      })
      yield call(() => {
        if (action.callback) {
          action.callback()
        }
      })
    } else {
      yield swal(locale.ERROR, res.text, 'error')
    }
  })
}

export function* onCheckInPostData() {
  yield takeEvery(actions.CHECK_IN_POST_DATA, function* (action) {
    const { locale } = yield call(getLang)
    const res = yield call(postCheckIn, action.payload.id)
    if (res.code === 'OK') {
      yield swal(locale.SUCCESS, locale.CHECK_IN_SUCCESS, 'success').then(
        action.payload.onSuccess
      )
    } else {
      yield swal(locale.ERROR, res.text.message, 'error').then(
        action.payload.onError
      )
    }
  })
}

export function* onAcceptInvitation() {
  yield takeEvery(actions.ON_ACCEPT_INVITATION, function* (action) {
    const { locale } = yield call(getLang)
    const { invitation_id, invitation_pin, callback } = action
    const res = yield call(acceptInvitation, invitation_id, invitation_pin)
    if (res.code === 'OK') {
      yield swal(locale.SUCCESS, locale.ACCEPT_SUCCESS, 'success')
    } else {
      yield swal(locale.ERROR, res.text.error, 'error')
    }

    callback()
  })
}

export default function* rootSaga() {
  yield all([
    fork(onLoad),
    fork(onLoadHomeLogin),
    fork(onLoadMyEvents),
    fork(onChooseEvent),
    fork(onChooseEventForFree),
    fork(onEditEvent),
    fork(onManageEvent),
    fork(onAddCalendar),
    fork(onShare),
    fork(onGoDetail),
    fork(onJoinFree),
    fork(onLoadUpcoming),
    fork(onLoadMyEventALl),
    fork(onLoadJoin),
    fork(onLoadHistory),
    fork(onLoadPublic),
    fork(onLoadSearch),
    fork(onLoadDetail),
    fork(onDetailSubmit),
    fork(onViewYourPass),
    fork(onJoinEvent),
    fork(onDeletePhotoGallery),
    fork(onDeleteDetailImageCover),
    fork(onDeleteDetailImagePlace),
    fork(onChangeImageCover),
    fork(onAddPhotoGallery),
    fork(onAddLogoImage),
    fork(onSubmitReplyMessage),
    fork(onCheckInPostData),
    fork(onLoadRepilesEvent),
    fork(onAcceptInvitation),
    fork(onLoadClubActivity),
    fork(onLoadGoodsDelivery),
  ])
}
