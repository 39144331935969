export default {
  JOIN: 'Join',
  EASILY_CREATE: 'EASILY CREATE ONLINE WEDDING INVITATIONS',
  JOIN_FOR_FREE: 'JOIN FOR FREE',
  START_YOUR_FIRST_EVENT: 'START YOUR FIRST EVENT',
  LATEST_EVENTS: 'Latest Events',
  FEATURES_PRICE_AND_PLAN: 'Features, Price and Plans',
  NEW: 'NEW',
  CREATE_JOIN_ANYTIME: 'Create or Join the event Anytime, Anywhere!',
  UPCOMING_EVENT: 'Upcoming Event',
  UPCOMING: 'Upcoming',
  MY_EVENT: 'My Events',
  JOINED: 'Joined',
  HISTORY: 'History',
  PUBLIC: 'Public',
  OUR_ACTIVITY: 'Our Activities',
  CLUB_LIST: 'Club List',
  CLUB_LIST_BUTTON: 'Enter club',
  CLUB_JOINED: 'Joined Club List',
  CLUB_PRMISSION_ALERT: 'You have not joined the association',
  CLUB_NO_PRMISSION: 'You do not have permission to access. Please contact administrator.',
  CLUB_SEND_REQUEST: 'Send request',
  SELL_ALL: 'See all',
  EDIT: 'Edit',
  SHARE: 'Share',
  MANAGE: 'Manage',
  ADD_TO_CALENDAR: 'Add to Calendar',
  MORE_DETAIL: 'More Detail',
  LOAD_MORE: 'Load More',
  CREATE_NEW_EVENTS: 'Create New Events',
  CHOOSE: 'Choose',
  HOME: 'Home',
  MY_CLUB: 'My Club',
  ACCOUNT: 'Account',
  EDIT_PROFILE: 'Edit Profile',
  EDIT_PERSONAL_INFO: 'Edit Personal Info',
  EDIT_PLACE: 'Edit Place & Location',
  EDIT_INFO: 'Edit Info',
  CHANGE_PASSWORD: 'Change Password',
  PAYMENT_HISTORY: 'Payment History',
  SIGN_IN: 'Sign In',
  SIGN_UP: 'Sign Up',
  SIGN_OUT: 'Sign out',
  PRICING: 'Pricing',
  JOIN_THIS_EVENT: 'JOIN THIS EVENT',
  VIEW_YOUR_PASS: 'VIEW YOUR PASS',
  MAP: 'Map',
  PLACE: 'Place',
  PHOTO_GALLERY: 'Photo Gallery',
  INVITATIONS: 'Invitations',
  IMPORT_INVITATION: 'Import Invitrees List',
  EXPORT_INVITATION: 'Export Invitrees List',
  EXPORT_INVITATION_FAIL: 'Export invitrees fail',
  NEW_INVITATION: 'New Invitation',
  TRANSACTIONS: 'Transaction',
  PACKAGE: 'Package',
  UPGRADE: 'Upgrade',
  TICKET_GOODS: 'TICKER&GOODS',
  UPGRADE_PACKAGE: 'Upgrade Package',
  NAME: 'Name',
  EMAIL_ADDRESS: 'Email Address',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  PLEASE_ENTER_PASSWORD: 'please enter password',
  FORGOT_PASSWORD: 'Forgot Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  OLD_PASSWORD: 'Old Password',
  NEW_PASSWORD: 'New Password',
  FULL_NAME: 'Full Name',
  PHONE_NUMBER: 'Phone Number',
  PHONE_NUMBER_PLACEHOLDER: '888888888',
  REMEMBER_ME: 'Remember me',
  CHANNEL: 'Channel',
  AMOUNT: 'Amount',
  DATE: 'Date',
  LAST_PAYMENT_DATE: 'Last Join Date',
  EVENT: 'Event',
  TYPE: 'Type',
  CREATED_DATE: 'Created Date',
  LAST_UPDATED_DATE: 'Last Updated Date',
  BTN_ON_PUBLISH: 'Published',
  BTN_OFF_PUBLISH: 'Publish',
  FROM: 'From',
  TO: 'To',
  STATUS: 'Status',
  NO_DATA: 'No Data',
  NO_ACCOUNT_SIGN_UP_NOW: 'Do not have an account? Sign up now.',
  I_AGREE: ['Agree to', 'Terms of use', 'and', 'Privacy Policy'],
  ALREADY_ACCOUNT: 'Already have an account? Sign in.',
  RESET: 'Reset',
  CHANGE: 'Change',
  SAVE: 'Save',
  SEND: 'Send',
  CLOSE: 'Close',
  SEND: 'Send',
  NO_EVENT: 'No Event',
  TRANSACTION_DETAIL: 'Transaction Detail',
  INVITATION_DETAIL: 'Invitation Detail',
  PAYMENT_HISTORY_DETAIL: 'Payment History Detail',
  MESSAGE: 'Message',
  SIGNATURE: 'Signature',
  LANGUAGE: 'Language',
  TRANSACTION_ID: 'Transaction ID',
  THB: 'THB',
  ARE_YOUR_GOING_JOIN: 'Are you going to join',
  ON: 'On',
  YES: 'Yes',
  NO: 'No',
  ERROR_PASSWORD_INCORRECT: 'Password incorrect',
  ERROR_PASSWORD_OLD_NEW_SAME: 'Password Old And New is same',
  ERROR_PASSWORD_NOT_MATCH: 'Password not match',
  LABEL_INPUT_NAME: 'Please Input Name',
  LABEL_INPUT_EMAIL: 'Please Input Email',
  WARNING_NAME_EMPTY: "Name Can't Empty",
  WARNING_EMAIL_EMPTY: "Email Can't Empty",
  WARNING_MESSAGE_EMPTY: "Message Can't Empty",
  WARNING_AMOUNT_EMPTY: "You Can't empty Amount",
  WARNING_AMOUNT_MINIMUM: 'Minimum Amount More than 0',
  WARNING_EMAIL_FORMAT_INCORRECT: 'Email Format Incorrect',
  WARNING_INVITATION_ID_MISSING: 'Invitation ID Missing',
  WARNING: 'Warning',
  ERROR: 'Error',
  SUCCESS: 'Your transaction is successful',
  JOIN_SUCCESS: 'You have successfully joined the event',
  SAVE_SUCCESS: 'Save Success',
  SAVE_FAIL: 'Save Fail',
  SEND_MESSAGE_SUCCESS: 'Send Message Success',
  CHANGE_PASSWORD_SUCCESS: 'Change Password Complete',
  SEND_INVITATION_SUCCESS: 'Send Success',
  SEND_INVITATION_FAIL: 'Send Fail',
  JOIN_INVITATION_FAIL:
    'You are not invited to joint this event, please contact the event owner',
  SEND_MESSAGE_FAIL: 'Send Message Fail',
  WARNING_WANT_DELETE: 'Do you want delete',
  DELETE_SUCCESS: 'Delete Success',
  DELETE_FAIL: 'Delete Fail',
  WARNING_UPLOAD_SUCCESS: 'Upload Success',
  WARNING_RESET_PASSWORD_SUCCESS: 'Reset Password Success',
  WARNING_UPLOAD_FAIL: 'Upload Fail',
  WARNING_CHECK_IN: 'Do you want to check in',
  OK: 'OK',
  CANCEL: 'Cancel',
  CHECK_IN: 'Check in',
  CHECK_IN_SUCCESS: 'Check in Success',
  WARNING_CANT_THIS_EVENT: "You Can't Join This Event",
  WARNING_INVALID_INPUT: 'Invalid Input',
  WARNING_INVALID_PHONE_NUMBER_INPUT: 'Invalid Phone Number',
  WARNING_PLEASE_REPLY_MESSAGE: 'Please Send Message',
  WARNING_PLEASE_INPUT_MESSAGE: 'Please Input Message',
  WARNING_EXPIRE_JOIN_EVENT: 'End of date Join Event',
  WARNING_MIN_PAYMENT: 'Minimum payment amount is 20 THB',
  REPLY_MESSAGE: 'Send Message',
  REPLY_MESSAGE_DESCRIPTION:
    'Kindly send messages and greeting messages to event owner via "Send" button',
  SUBMIT: 'Submit',
  VERIFICATION_CODE: 'Verification Code',
  CONTINUE: 'Continue',
  PLEASE_SPECIFY_THE_AMOUNT: 'Please specify the amount to join the event',
  LABEL_INFO_TITLE_ENG: 'Name (English)',
  LABEL_INFO_TITLE_TH: 'Name (Thai)',
  LABEL_INFO_DESC_ENG: 'Description (English)',
  LABEL_INFO_DESC_TH: 'Description (Thai)',
  LABEL_PLACE_ADDRESS_ENG: 'Address (English)',
  LABEL_PLACE_ADDRESS_TH: 'Address (ไทย)',
  LABEL_PLACE_LAT: 'Latitude',
  LABEL_PLACE_LNG: 'Longitude',
  RECEIVER_NAME: 'Receiver Name',
  RECEIVER_EMAIL: 'Receiver Email',
  ERROR_NOT_LOGIN: 'Please log in',
  ERROR_NOT_OWNER_EVENT: 'Please log in as event owner account to check in',
  ERROR_ALREADY_CHECKIN: "You've already checked in",
  TRANSACTION_STATUS_FAILED: 'Failed',
  TRANSACTION_STATUS_EXPIRED: 'Expired',
  TRANSACTION_STATUS_PENDING: 'Pending',
  TRANSACTION_STATUS_REVERSED: 'Reversed',
  TRANSACTION_STATUS_SUCCESSFUL: 'Successful',
  TRANSACTION_PAYMENT_CARD: 'Credit Card',
  TRANSACTION_PAYMENT_INTERNET_BANKING: 'Internet Banking',
  TRANSACTION_ORDER_TYPE_CREATED: 'Created',
  TRANSACTION_ORDER_TYPE_PAID: 'Paid',
  TRANSACTION_ORDER_TYPE_REFUNDED: 'Refunded',
  TRANSACTION_ORDER_TYPE_JOIN: 'Join',
  TRANSACTION_ORDER_TYPE_CREATE: 'Create',
  TRANSACTION_ORDER_TYPE_UPGRADE: 'Upgrade',
  JOIN_TYPE: 'Join Type',
  PRIVACY: 'Privacy',
  WEBSITE: 'Website',
  FACEBOOK: 'Facebook',
  PRIVATE: 'Private',
  PUBLIC_VIEW: 'Public View',
  PUBLIC_JOIN: 'Public Join',
  CLUB_JOIN: 'Club',
  PAID_OR_FREE: 'Paid or Free',
  FREE: 'Free',
  PAID: 'Paid',
  DISABLED: 'Disabled',
  ENABLED: 'Enabled',
  BANNED: 'Banned',
  CLOSED: 'closed',
  PRICE_FOR_THE_PLAN: 'Package Price',
  NUMBER_OF_INVITEES_PER_EVENT: 'Number of Invitees per Event',
  AVAILABLE_ON_WEBSITE:
    'Available on Website and Application (App store and Google play)',
  GOOGLE_CALENDAR_MAP: 'Google Calendar and Google Map Plugin',
  QR_CODE_FOR_REGIS: 'QR Code for Registration',
  UNLIMITED_PHOTO_STORAGE: 'Unlimited Photo Storage',
  PUSH_EVENT_NOTIFICATION: 'Push Event Notification',
  GREETING_MESSAGE: 'Greeting Message',
  SUMMARY_OF_EVENT_PARTICIPANTS: 'Summary of event participants',
  ONLINE_PAYMENT: 'Online Payment (Credit Card and Internet Banking)',
  IMPORT_INVITATION_DETAIL:
    'You can send the invitation to multiple guests at the same time with an xlsx or csv file containing name, email, invitation message and the language of invitation email ("EN" of "TH"). Then, import the file to Invitree.',
  IMPORT_INVITATION_DETAIL_NEXT:
    'The xlsx and csv file to be imported must have a header at the first row. The header must contains the column names as "NAME" and "EMAIL" Click the link to download',
  XLSX_TEMPLATE: 'xlsx template',
  AND: 'and',
  MESSAGE: 'message',
  CSV_TEMPLATE: 'csv template',
  FULL_STOP: '.',
  ERROR_UPLOAD_CSV_XLSX:
    'Invalid file extension. It must be a .csv or .xlsx format only.',
  DOWNLOAD: 'Download',
  FILE_EXTENSION: 'File format',
  ACTIONS: 'Actions',
  TABLE_MENU_ACTION: 'ACTION',
  RESEND: 'Resend',
  REVOKE: 'Revoke',
  RESEND_FAIL: 'Resend Fail',
  RESEND_SUCCESS: 'Resend Success',
  RESENDING: 'Resending',
  REVOKING: 'Revoking',
  REVOKE_FAIL: 'Revoke Fail',
  REVOKE_SUCCESS: 'Revoke Success',
  ACCEPT: 'Accept',
  ACCEPT_THIS_EVENT: 'Accept this event',
  ACCEPT_SUCCESS: 'Accept success',
  IMAGE_CROPPER: 'Crop Image',
  SELECT_PLACE_ON_MAP: 'Select Place on Map',
  SELECT: 'Select',
  UPDATE_INFORMATION: 'Please update your personal information',
  PERSONAL_INFORMATION: 'PERSONAL INFORMATION',
  CLUB_STATUS: 'Club Status',
  MEMBER: 'Member',
  ADMINISTRATOR: 'Administrator',
  INVITREE_ACCOUNT: 'Invitree Account',
  NOT_A_MEMBER: 'Not a member',
  DO_NOT_HAVE_ACCOUNT: `Don't have an account`,
  ALREADY_HAVE_ACCOUNT: 'Already have an account',
  NAME_AND_FAMILY_NAME: 'Name and Family Name',
  ENTER_NAME_AND_FAMILY_NAME: 'Enter Name and Family name',
  NICKNAME1: 'Nickname 1',
  ENTER_NICKNAME1: 'Enter Nickname 1',
  NICKNAME2: 'Nickname 2',
  ENTER_NICKNAME2: 'Enter Nickname 2',
  FACULTY: 'Faculty',
  ENTER_FACULTY: 'คณะ วิศวกรรมศาสตร์',
  CURRICULUM: 'Curriculum',
  ENTER_CURRICULUM: 'Enter Curriculum',
  CLASS_YEAR: 'Class of',
  ENTER_CLASS_YEAR: '',
  CLASS_OF: 'Year of admission',
  ENTER_CLASS_OF: 'Enter Class Year',
  PHONE_NUMBER: 'Phone Number',
  ENTER_PHONE_NUMBER: 'Enter phone number',
  STUDENT_ID: 'Student ID',
  ENTER_STUDENT_ID: 'Enter Student ID',
  MAJOR: 'Major',
  ENTER_MAJOR: 'Enter Major',
  LINE_ID: 'Line ID',
  ENTER_LINE_ID: 'Enter Line ID',
  LINE_ID: 'Line ID',
  POSITION: 'Position',
  ENTER_POSITION: 'Enter your current Position',
  COMPANY_DEPARTMENT: 'Company / Department',
  ENTER_COMPANY_DEPARTMENT: 'Enter your company/department',
  BUSINESS_TYPE: 'Industrial / Business type',
  ENTER_BUSINESS_TYPE: 'Enter your Industrial / Business type',
  SKILL_EXPERIENCE: 'Skills and Experience',
  ENTER_SKILL_EXPERIENCE: 'Enter your skills and experience',
  ADD_WORKPLACE: 'Add a workplace',
  STILL_WORKING: 'Still Working',
  RETIRED: 'Retired',
  CURRENT_ADDRESS: 'Current Address',
  ENTER_YOUR_CURRENT_ADDRESS: 'Enter your current address',
  E_MAIL: 'E-mail',
  ENTER_YOUR_E_MAIL: 'Enter your E-mail',
  FACEBOOK: 'Facebook',
  ENTER_YOUR_FACEBOOK_NAME: 'Enter your facebook name',
  SKIP: 'Skip',
  SAVE: 'Save',
  PUBLISH: 'Publish',
  ACTIVITIES: 'Activities',
  WARNING_CLUB_LOGIN:
    'If you would like to see the list of others in your club, Please Sign in to be an Invitree account',
  WEBSITE: 'Website',
  UPCOMING_EVENTS: 'UPCOMING EVENTS',
  SEE_THIS_EVENT: 'See this event',
  SHARE_ACTIVITY: 'SHARE ACTIVITY',
  PAST_EVENTS: 'PAST EVENTS',
  TICKET: 'Ticket',
  TICKET_PRICE: 'TICKET PRICE',
  PRICE: 'Price',
  TOTAL_PRICE: 'Total Price',
  QUANTITY: 'Quantity',
  SELECT_PAYTMENT_METHOD: 'SELECT PAYMENT METHOD',
  PAYMENT_BY_QR_CODE: 'Payment by QR Code',
  PATMENT_BY_CREDIT_CARD: 'Payment by Credit Card',
  BUY_TICKET: 'Buy Ticket',
  PLEASE_COMPLETE_PERSONAL_INFORMATION:
    'please complete your personal information before joining the club to attend club events.',
  OK: 'OK',
  ACTIVITY_SCHEDULE: 'ACTIVITY SCHEDULE',
  JOIN_THIS_EVENT: 'JOIN THIS EVENT',
  ADD_TO_CALENDAR: 'ADD TO CALENDAR',
  ADD_MEMBER: 'ADD MEMBER',
  SEND_INVITE: 'SEND INVITATION',
  SEND_INVITE_ALL: 'SEND BULK INVITATION',
  EDIT_MEMBER_INFO: 'Edit Member Info',
  ADD_MEMBER_MOBILE: 'ADD',
  ORDER_SUMMARY: 'Order Summary',
  REVIEW_ORDER_SUMMARY: 'REVIEW ORDER SUMMARY',
  PAYMENT: 'Payment',
  ITEM: 'Item',
  FEE: 'Fee',
  SUBTOTAL: 'Subtotal',
  TOTAL: 'Total',
  SERVICE_CHARGE: 'Service charge',
  VAT: 'Vat 7%',
  GRAND_TOTAL: 'Grand Total',
  WARNING_REFUND_CONDITION:
    'If you have a seat reserved after successfully paying for any tickets and/or services; in any case you do not pick up your ticket at the event on time and/or show up at the event, we reserve the right not to issue a refund.',
  BACK_TO_EDIT: 'BACK TO EDIT',
  CONFIRM: 'CONFIRM',
  MEMBER_NAME: 'MEMBER NAME',
  REQUESTER_NAME: 'REQUESTER NAME',
  WARNING_MEMBER_APPROVE: 'Are you sure to approve this request?',
  APPROVE: 'APPROVE',
  WARNING_MEMBER_REJECT: 'Are you sure to reject this request?',
  REJECT: 'REJECT',
  SIGNIN_INFO_KMITL: 'สมาคมศิษย์เก่าคณะวิศวกรรมศาสตร์ สจล.\n สงวนสิทธิ์การเข้าร่วมเฉพาะศิษย์เก่า\n ศิษย์ปัจจุบัน และผู้ที่เคยศึกษาในคณะวิศวกรรมศาสตร์เท่านั้น',
  SIGNUP_INFO_KMITL: 'กรุณากรอกชื่อและอีเมลเพื่อขอเข้าร่วมสมาคมศิษย์เก่าคณะวิศวกรรมศาสตร์ สจล. คณะผู้ดูแลระบบจะทำการส่งอีเมลเพื่อติดต่อท่านเพื่อยืนตัวตนอีกครั้ง',
  ADDITIONAL_INFORMATION_CLUB_TITLE: 'Additional Information (if any)',
  ADDITIONAL_INFORMATION_CLUB_REQUEST: '- Name-Surname while studying\n- Student ID\n- Year of admission\n- Major\n- Project advisor\n- Friends in the class that can reference',
  REQUEST_TO_CLUB_SUCCESS:'The request has been sent to a admin. Please wait for an email.',
  REQUEST_TO_CLUB_NO_SURNAME: 'Please enter surname',
  REINVITE_CLUB_MEMBER: 'Are you sure to reinvite this member?',
  REINVITE_SUCCESS: 'Successfully reinvited members.',
  EMAIL_NOT_VALID: 'Email is not valid!',
  POSITION: 'POSITION',
  OWNER: 'Owner',
  ADMIN: 'Admin',
  PHOTO_GALLERY: 'PHOTO GALLERY',
  SEE_MORE_PHOTO: 'See more photo',
  PLACE: 'PLACE',
  MAP: 'MAP',
  QR_CODE_PAYMENT: 'QR Code Payment',
  SCAN_AND_PAY_WITH_BANK: 'Scan and pay with your bank application or',
  SAVE_AND_PAY_LATER: 'Save and pay later',
  SCAN_AND_PAY_DESKTOP: 'Scan to pay with any bank application',
  SAVE_AND_PAY_MOBILE: 'Save QR Code to pay with any bank application',
  SAVE_QR_CODE: 'Save QR Code',
  COMPLETED: 'Completed',
  HOW_TO_PAY_WITH: 'How to pay with',
  YEAR: 'Year',
  MONTH: 'Month',
  UNTIL_NOW: 'Until now',
  WARNING_REGISTER_KMITL:
    'Please complete your Personal Information to join the "KMITL Engineer Alumni" club',
  WARNING_PUBLISH: 'Information will be published in this club only.',
  ERROR_REGISTER_PHONE:
    'Input only number, without special character or space.',
  ERROR_REGISTER_EMAIL: 'Please enter a valid email address to continue',
  ERROR_REGISTER_PASSWORD: 'Please enter a valid password to continue',
  SUCCESS_REGISTER_KMITL: 'Information successfully updated',
  LIST_ALL: 'ALL',
  LIST_NAME: 'NAME',
  LIST_LAST_NAME: 'LASTNAME',
  LIST_NICKNAME: 'NICKNAME',
  LIST_CLASS_YEAR: 'CLASS YEAR',
  LIST_CLASS_OF: 'CLASS OF',
  LIST_MAJOR: 'MAJOR',
  ENTER_FIRST_LOGIN_USER:
    'At first log in, use your registered name-surname when you studied.',
  ENTER_FIRST_LOGIN_STUDENT_ID:
    'Input only number, without special character or space.',
  WARNING_DELETE_MEMBER: 'Are you sure to delete this member?',
  ASSIGN_ADMIN: 'Assign admin',
  REMOVE_ADMIN: 'Remove admin',
// ========== Message in Club section ==============

SELECT_MAJOR: 'Select Major',

// ========== Error return form Backend ==============

USER_PROFILE_INFO_ALREADY_EXIST: 'User Profile Info Already Exist.',
INVITREE_USERNAME_ALREADY_EXIST: 'Username Already Exist:Invitree Username Already Exist.',

// ========== Message in BUY TICKET PAGE ==============
TICKET_DETAIL: 'TICKET DETAIL',
GOODS_AND_SOUVENIR: 'GOODS and SOUVENIR',
DELIVERY: 'DELIVERY',
SELECT_PAYMENT: 'SELECT PAYMENT METHOD',
DELIVERY_ADDRESS: 'Delivery address',
ADDRESS: 'Address',
TEL: 'Tel',
PAY_NOW: 'PAY NOW',
QR_EXPIRE: 'Pay within',
WARNING_GEN_QR_FAIL: "Can not request QR code",
PREVIEW_IMG: 'Preview'
}
