import { combineReducers } from 'redux'
import auth from './auth/reducer'
import menu from './menu/reducer'
import app from './app/reducer'
import home from './home/reducer'
import payment from './payment/reducer'
import invitation from './invitation/reducer'
import transaction from './transaction/reducer'
import clubRegister from './club-register/reducer'

export default combineReducers({
  auth,
  menu,
  app,
  home,
  payment,
  invitation,
  transaction,
  clubRegister,
})
