import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import Cookies from 'js-cookie'
import { navigate } from 'gatsby-link'
import swal from 'sweetalert'

import {
  AuthenticationHelper,
  changePassword,
  currentAuthenticateUser,
  forgotPassword,
  getFacebookToken,
  resetPassword,
  setFacebookToken,
  signIn,
} from '../../utils/auth-utils'

import {getProfile} from '../../utils/api'
import actions from './actions'
import ModalHandler from '../../utils/modal'

const getLang = function*() {
  const app = yield select(state => state.app)
  return {
    lang: app.lang,
    locale: app.locale[app.lang],
  }
}

export function* signInRequest() {
  yield takeEvery(actions.SIGN_IN_REQUEST, function*(action) {
    try {
      yield put({ type: actions.SIGN_IN_ERROR, error: { message: null } })
      const user = yield call(doSignIn, action.username, action.password)
      yield put({ type: actions.SIGN_IN_SUCCESS, user })
    } catch (err) {
      yield put({ type: actions.SIGN_IN_ERROR, error: err })
    }
  })
}

export function* signInSuccess() {
  yield takeEvery(actions.SIGN_IN_SUCCESS, function*(action) {
    const { challengeName } = action.user
    if (challengeName === 'NEW_PASSWORD_REQUIRED') {
      yield put({ type: actions.SET_CHALLENGE_USER, user: action.user })
      yield call(() => {
        navigate('/complete-new-password/')
      })
    } else {
      yield put({ type: actions.SET_CURRENT_USER, user: action.user })
      yield call(() => {
        navigate('/insurance/')
      })
    }
  })
}

export function* signInError() {
  yield takeEvery(actions.SIGN_IN_ERROR, function*() {})
}

export function* signOutRequest() {
  yield takeEvery(actions.SIGN_OUT_REQUEST, function*() {
    try {
      // const data = yield call(doSignOut)
      yield put({ type: actions.SIGN_OUT_SUCCESS })
    } catch (error) {
      yield put({ type: actions.SIGN_OUT_ERROR, error })
    }
  })
}

export function* signOutSuccess() {
  yield takeEvery(actions.SIGN_OUT_SUCCESS, function*() {
    yield put({ type: actions.SET_CURRENT_USER, user: null })
    yield call(() => {
      navigate('/')
    })
  })
}

export function* signOutError() {
  yield takeEvery(actions.SIGN_OUT_ERROR, function*() {})
}

export function* onOAuthLogin() {
  yield takeEvery(actions.OAUTH_LOGIN, function*(action) {
    try {
      yield call(setFacebookToken, action.access_token)
      yield setTimeout(() => {
        navigate('/home')
      }, 300)
    } catch (e) {
      const { locale } = yield call(getLang)
      yield swal(locale.ERROR, e, 'error')
    }
  })
}

function checkTempJoinEventID() {
  const id = Cookies.get('tempJoinEventID')
  if (id) {
    Cookies.remove('tempJoinEventID')
  }
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*(action) {
    const redirectUrl = action.authorizedRedirect
    try {
      const user = yield call(doGetCurrentUser)
      const pdpaverified = yield call(dogetProfile)
      yield put({ type: actions.SET_CURRENT_USER, user })
      yield put({ type: actions.CHECK_USER_PDPA, pdpaverified })
      yield checkTempJoinEventID()

      if (redirectUrl) {
        yield call(() => {
          navigate(redirectUrl)
        })
      }
      if (action.authorizedAction) {
        yield put(action.authorizedAction)
      }

      if (action.options) {
        const onSuccess = action.options.onSuccess
        if (onSuccess) {
          onSuccess(user)
        }
      }
    } catch (err) {
      const facebookToken = getFacebookToken()
      if (facebookToken) {
        yield put({
          type: actions.SET_CURRENT_USER,
          user: { currentUser: facebookToken },
        })
        yield checkTempJoinEventID()
        if (action.options) {
          const onSuccess = action.options.onSuccess
          if (onSuccess) {
            onSuccess(facebookToken)
          }
          const onError = action.options.onError
          if (onError) {
            onError('IS_OAUTH_BY_PASS')
          }
        }
      } else {
        const checkRemember = Cookies.get('IS_REMEMBER')
        if (checkRemember === 'YES') {
          AuthenticationHelper.signIn({
            username: Cookies.get('REMEMBER_USERNAME'),
            password: Cookies.get('REMEMBER_PASSWORD'),
            onSuccess: () => {
              if (redirectUrl) {
                call(() => {
                  navigate(redirectUrl)
                })
              }
              if (action.options) {
                const onSuccess = action.options.onSuccess
                if (onSuccess) {
                  onSuccess(null)
                }
              }
            },
            onError: () => {
              if (redirectUrl) {
                call(() => {
                  navigate(redirectUrl)
                })
              }
            },
          })
        } else {
          yield put({ type: actions.SET_CURRENT_USER, user: null })

          if (action.options) {
            const onSuccess = action.options.onSuccess
            if (onSuccess) {
              onSuccess(null)
            }
          }

          if (action.options) {
            const onError = action.options.onError
            if (onError) {
              onError(err)
            }
          }

          if (action.unauthorizedRedirect) {
            navigate(action.unauthorizedRedirect)
          }
        }
      }
    }
  })
}

export function* changePasswordRequest() {
  yield takeEvery(actions.CHANGE_PASSWORD, function*(action) {
    const { locale } = yield call(getLang)
    try {
      const { user, oldPassword, newPassword } = action
      yield call(doChangePassword, user, oldPassword, newPassword)
      yield call(() => {
        ModalHandler.closeModal('modalChangePassword')
        swal(locale.SUCCESS, locale.CHANGE_PASSWORD_SUCCESS, 'success')
      })
    } catch (err) {
      yield swal(locale.ERROR, err.message, 'error')
    }
  })
}

export function* completeNewPasswordRequest() {
  yield takeEvery(actions.COMPLETE_NEW_PASSWORD, function*(action) {
    // try {
    //   const { user, newPassword } = action
    //   yield call(doCompleteNewPassword, user, newPassword)
    //   yield call(() => {
    //     ModalHandler.closeModal('modalChangePassword')
    //     swal('Success', 'Change Password Complete!', 'success')
    //   })
    //   yield call(() => {
    //     ModalHandler.closeModal('modalChangePassword')
    //   })
    // } catch (err) {
    //   yield swal('Error', err.message, 'error')
    // }
  })
}

export function* forgotPasswordRequest() {
  yield takeEvery(actions.FORGOT_PASSWORD, function*(action) {
    try {
      const { username, fn } = action
      const res = yield call(doForgotPassword, username)
      yield call(() => {
        // ModalHandler.closeModal('modalResetPassword')
        //   navigate('/reset-password/')
        fn({ username, ...res })
      })
    } catch (err) {
      const { locale } = yield call(getLang)
      yield swal(locale.ERROR, err.message, 'error')
    }
  })
}

export function* resetPasswordRequest() {
  yield takeEvery(actions.RESET_PASSWORD, function*(action) {
    try {
      const { username, code, newPassword, fn } = action
      const res = yield call(doResetPassword, username, code, newPassword)
      yield call(() => {
        fn({ ...res })
      })
    } catch (err) {
      const { locale } = yield call(getLang)
      yield swal(locale.ERROR, err.message, 'error')
    }
  })
}

export function* handleError() {
  yield takeEvery(actions.HANDLE_ERROR, function*() {})
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(signInRequest),
    fork(signInSuccess),
    fork(signInError),
    fork(signOutRequest),
    fork(signOutSuccess),
    fork(signOutError),
    fork(changePasswordRequest),
    fork(completeNewPasswordRequest),
    fork(forgotPasswordRequest),
    fork(resetPasswordRequest),
    fork(handleError),
    fork(onOAuthLogin),
  ])
}

const doSignIn = async (username, password) => {
  return signIn(username, password)
}

// const doSignOut = async () => {
//   return signOut()
// }

const doGetCurrentUser = async () => {
  return currentAuthenticateUser()
}

const dogetProfile = async () => {
  const data = await getProfile()
  return data.data.pdpaverified
}

const doChangePassword = async (user, oldPassword, newPassword) => {
  return changePassword(user, oldPassword, newPassword)
}

// const doCompleteNewPassword = async (user, newPassword) => {
//   return completeNewPassword(user, newPassword)
// }

const doForgotPassword = async username => {
  return forgotPassword(username)
}

const doResetPassword = async (username, code, newPassword) => {
  return resetPassword(username, code, newPassword)
}
