import { Map } from 'immutable'
import Cookies from 'js-cookie'
import Amplify, { Auth } from 'aws-amplify'
import config from './config'
import { oAuthGetToken, syncProfileCognito } from './api'

export function setupAuth() {
  Amplify.configure({
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: config.cognito.IDENTITY_POOL_ID,

      // REQUIRED - Amazon Cognito Region
      region: config.cognito.REGION,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: config.cognito.USER_POOL_ID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: config.cognito.APP_CLIENT_ID,

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,

      // OPTIONAL - Configuration for cookie storage
      // cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      // domain: 'backoffice-dev.aappoint.com',
      // OPTIONAL - Cookie path
      // path: '/',
      // OPTIONAL - Cookie expiration in days
      // expires: 365,
      // OPTIONAL - Cookie secure flag
      // secure: true
      // }
    },
  })
}

export function clearLocalSession() {
  localStorage.removeItem('recurring_donation_session')
}

export function getAccessToken() {
  try {
    const appService =
      'CognitoIdentityServiceProvider.' + config.cognito.APP_CLIENT_ID
    const uuid = localStorage.getItem(appService + '.LastAuthUser')
    const accessToken = appService + '.' + uuid + '.accessToken'
    let session = localStorage.getItem(accessToken)
    if (session) {
      return new Map({ accessToken: session })
    }
  } catch (err) {
    clearToken()
  }
  return new Map()
}

export function getIdToken() {
  try {
    const appService =
      'CognitoIdentityServiceProvider.' + config.cognito.APP_CLIENT_ID
    const uuid = localStorage.getItem(appService + '.LastAuthUser')
    const idToken = appService + '.' + uuid + '.idToken'
    let session = localStorage.getItem(idToken)
    if (session) {
      return new Map({ idToken: session })
    }
  } catch (err) {
    clearToken()
  }
  return new Map()
}

export function clearToken() {
  console.log('ClearToken')
}

export function getSession() {
  return Auth.currentSession()
}

export function signIn(username, password) {
  username = username.toLowerCase()
  return Auth.signIn(username, password)
}

export function signUp(username, password, attributes) {
  username = username.toLowerCase()
  attributes.email = attributes.email.toLowerCase()
  attributes.phone_number = attributes.phone_number.replace(/-/g, '')
  attributes.phone_number = attributes.phone_number.replace(/\s/g, '')

  return Auth.signUp({
    username,
    password,
    attributes,
    validationData: [],
  })
}

export function signOut() {
  clearFacebookToken()
  return Auth.signOut()
}

export function forgotPassword(username) {
  username = username.toLowerCase()
  return Auth.forgotPassword(username)
}

export function resetPassword(username, code, new_password) {
  username = username.toLowerCase()
  return Auth.forgotPasswordSubmit(username, code, new_password)
}

export function changePassword(user, old_password, new_password) {
  user = user.toLowerCase()
  return Auth.changePassword(user, old_password, new_password)
}

export function completeNewPassword(user, new_password) {
  user = user.toLowerCase()
  return Auth.completeNewPassword(user, new_password, {
    requiredAttributes: [],
  })
}

export function currentAuthenticateUser() {
  return Auth.currentAuthenticatedUser()
}

export function currentUserInfo() {
  return Auth.currentUserInfo()
}

class AuthenticationHelperClass {
  options = {
    signIn: {
      username: '',
      password: '',
      onSuccess: function(userDetail) {
        console.log('SignUp Success!', userDetail)
      },
      onError: function(errorMessage) {
        console.log('SignUp Error!', errorMessage)
      },
    },
    signUp: {
      fullName: '',
      email: '',
      password: '',
      password2: '',
      onSuccess: function(userDetail) {
        console.log('SignUp Success!', userDetail)
      },
      onError: function(errorMessage) {
        console.log('SignUp Error!', errorMessage)
      },
    },
  }

  async signIn(options = this.options.signIn) {
    let { username, password } = options
    if (username && username.length === 0) {
      options.onError('Email Empty!')
      return
    }

    if (password && password.length === 0) {
      options.onError('Password Empty!')
      return
    }

    try {
      const res = await signIn(username, password)
      await syncProfileCognito()
      options.onSuccess(res)
    } catch (e) {
      if (e.message == 'Custom auth lambda trigger is not configured for the user pool.'){
        e.message = 'Error!!! Password is empty!'
      }
      options.onError(e.message)
    }
  }

  async signUp(options = this.options.signUp) {
    if (options.fullName.length === 0) {
      options.onError('Full Name Empty!')
      return
    }

    if (options.email.length === 0) {
      options.onError('Email Empty!')
      return
    }

    if (options.phone_number.length === 0) {
      options.onError('Phone Number Empty!')
      return
    }

    if (options.phone_number.charAt(0) !== '+') {
      options.onError('Missing Phone Number Country Code!')
      return
    }

    if (options.password.length === 0 || options.password2.length === 0) {
      options.onError('Password Empty!')
      return
    }

    if (options.password !== options.password2) {
      options.onError('Password Not Match!')
      return
    }

    try {
      const res = await signUp(options.email, options.password, {
        email: options.email,
        name: options.fullName,
        phone_number: options.phone_number,
      })
      await signIn(options.email, options.password)
      await syncProfileCognito()
      // const resLogin = await signIn(options.email, options.password)
      options.onSuccess(res.user)
    } catch (e) {
      options.onError(e.message)
    }
  }
}

export const AuthenticationHelper = new AuthenticationHelperClass()

/*
    ตอนกดปุ่ม login ด้วย facebook จะรบกวนให้ไปที่ url นี้ได้มั๊ยครับ

    https://auth.app-dev.invitree.me/oauth2/authorize?identity_provider=Facebook&redirect_uri=https://app-dev.invitree.me&response_type=CODE&client_id=7lpkkh8q9bqh6h63jc4fc457mj&scope=email%20openid

    ตรง redirect_uri ให้แก้เป็น url ที่จะ redirect ไปเมื่อ login สำเร็จ
    เช่น จาก url ข้างบนพี่ใช้  redirect_uri เป็น https://app-dev.invitree.me พอ login สำเร็จมันก็จะ redirect มาที่ https://app-dev.invitree.me/?code=xxxxxxxxxx  แล้วเอา code ที่ได้มายิงไปที่  TOKEN endpoint อีกทีเพื่อเอา idToken กับ access token โดยใช้ grant_type เป็น authorization_code ดูตาม link ข้างล่าง
    https://docs.aws.amazon.com/cognito/latest/developerguide/token-endpoint.html

    จริงๆก็คือ พอกดปุ่ม login with facebook ก็ยิงไปที่ AUTHORIZATION Endpoint อ่ะครับ ตาม doc https://docs.aws.amazon.com/cognito/latest/developerguide/authorization-endpoint.html

    แล้วเอา code ที่ได้มายิงไปขอ token อีกรอบที่ TOKEN Endpoint
    https://docs.aws.amazon.com/cognito/latest/developerguide/token-endpoint.html
*/

export function getFacebookToken() {
  return Cookies.get('accessTokenFacebook')
}

export function getFacebookIDToken() {
  return Cookies.get('idTokenFacebook')
}

export function clearFacebookToken() {
  Cookies.remove('accessTokenFacebook')
  Cookies.remove('idTokenFacebook')
}

export function facebookLogin() {
  window.location = process.env.GATSBY_FACEBOOK_LOGIN_URL
}

export function LineLogin() {
  window.location = process.env.GATSBY_LINE_LOGIN_URL
}

function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60000)
}

export async function setFacebookToken(accessToken) {
  try {
    const data = await oAuthGetToken(accessToken)
    const expires = addMinutes(new Date(), 60)
    Cookies.set('accessTokenFacebook', data.access_token, { expires })
    Cookies.set('idTokenFacebook', data.id_token, { expires })
    await syncProfileCognito()
  } catch (e) {
    clearFacebookToken()
  }
}
