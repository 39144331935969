import { all, fork, put, takeEvery } from 'redux-saga/effects'
import { navigate } from 'gatsby-link'
import actions from './actions'

export function* onChangeLanguage() {
  yield takeEvery(actions.APP_CHANGE_LANGUAGE, function*(action) {
    yield put({
      type: actions.SET_LANGUAGE,
      payload: {
        lang: action.payload,
        tempLocale: window.location.href.split(window.location.origin)[1],
      },
    })
    yield navigate('/locale-reload')
  })
}

export default function* rootSaga() {
  yield all([fork(onChangeLanguage)])
}
