import { notification } from 'antd'
import 'antd/lib/notification/style/css'

export const notificationSuccess = (message, description) =>
  notification['success']({ message, description })

export const notificationInfo = (message, description) =>
  notification['info']({ message, description })

export const notificationWarning = (message, description) =>
  notification['warning']({ message, description })

export const notificationError = (message, description) =>
  notification['error']({ message, description })
