import actions from './actions'

const initState = {
  currentUser: null,
  challengeUser: null,
  errorMessage: null,
  pdpaVerified: null,
}

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_CURRENT_USER:
      return { ...state, currentUser: action.user, challengeUser: null }
    case actions.CHECK_USER_PDPA:
      return { ...state, pdpaVerified: action.pdpaverified }
    case actions.SET_CHALLENGE_USER:
      return { ...state, currentUser: null, challengeUser: action.user }
    case actions.SIGN_IN_ERROR:
      return { ...state, errorMessage: action.error.message }
    case actions.HANDLE_ERROR:
      return { ...state, errorMessage: action.error.message }
    default:
      return state
  }
}
