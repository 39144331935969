const actions = {
  SET_PAYMENT: 'SET_PAYMENT_STATUS',
  SET_PAYMENT_HISTORY_LIST: 'SET_PAYMENT_HISTORY_LIST',
  SET_PAYMENT_HISTORY_DETAIL: 'SET_PAYMENT_HISTORY_DETAIL',
  SET_PAYMENT_ONLOAD: 'SET_PAYMENT_ONLOAD',
  SET_BUT_TICKET_DETAIL: 'SET_BUT_TICKET_DETAIL',

  ON_AUTO_SUBMIT: 'ON_AUTO_SUBMIT',
  onAutoSubmit: payload => ({
    type: actions.ON_AUTO_SUBMIT,
    payload,
  }),

  ON_PAYMENT: 'PAYMENT_SUBMIT',
  onPaymentSubmit: payload => ({
    type: actions.ON_PAYMENT,
    payload,
  }),

  ON_CHARGE: 'PAYMENT_CHARGE_SUBMIT',
  onChargeSubmit: (orderID, payload) => ({
    type: actions.ON_CHARGE,
    orderID,
    payload,
  }),

  ON_PAYMENT_HISTORY_LOAD: 'ON_PAYMENT_HISTORY_LOAD',
  onPaymentHistoryLoad: payload => ({
    type: actions.ON_PAYMENT_HISTORY_LOAD,
    payload,
  }),

  ON_PAYMENT_HISTORY_DETAIL: 'ON_PAYMENT_HISTORY_DETAIL',
  onPaymentHistoryDetail: payload => ({
    type: actions.ON_PAYMENT_HISTORY_DETAIL,
    payload,
  }),

  ON_PAYMENT_GET_DETAIL: 'ON_PAYMENT_GET_DETAIL',
  onPaymentGetDetail: payload => ({
    type: actions.ON_PAYMENT_GET_DETAIL,
    payload,
  }),

  ON_PAYMENT_LOAD: 'ON_PAYMENT_LOAD',
  onPaymentLoad: payload => ({
    type: actions.ON_PAYMENT_LOAD,
    payload,
  }),

  ON_SUBMIT_BUT_TICKET: 'ON_SUBMIT_BUT_TICKET',
  onSubmitAmountTicket: payload => ({
    type: actions.ON_SUBMIT_BUT_TICKET,
    payload,
  }),
}

export default actions
