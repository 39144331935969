export default {
  JOIN: 'เข้าร่วม',
  EASILY_CREATE: 'EASILY CREATE ONLINE WEDDING INVITATIONS',
  JOIN_FOR_FREE: 'เข้าร่วมฟรี',
  START_YOUR_FIRST_EVENT: 'เริ่มต้นอีเว้นท์แรกของคุณ',
  LATEST_EVENTS: 'อีเว้นท์ล่าสุด',
  FEATURES_PRICE_AND_PLAN: 'คุณสมบัติ ราคา และ แผน',
  NEW: 'ใหม่',
  CREATE_JOIN_ANYTIME: 'สร้างหรือเข้าร่วมอีเวนท์ได้ทุกที่ทุกเวลา',
  UPCOMING_EVENT: 'Event เร็วๆนี้',
  UPCOMING: 'กำลังจะเริ่ม',
  JOINED: 'เข้าร่วม',
  HISTORY: 'ประวัติการใช้งาน',
  PUBLIC: 'สาธารณะ',
  OUR_ACTIVITY: 'กิจกรรมของเรา',
  CLUB_LIST: 'รายชื่อคลับ',
  CLUB_LIST_BUTTON: 'เข้าสู่สมาคม',
  CLUB_JOINED: 'คลับที่คุณเข้าร่วม',
  CLUB_PRMISSION_ALERT: 'คุณยังไม่ได้เข้าร่วมกับสมาคม',
  CLUB_NO_PRMISSION: 'คุณไม่มีสิทธิ์ในการเข้าใช้งานสมาคม หากต้องการเข้าสามาคมกรุณาติดต่อผู้ดูแลระบบ',
  CLUB_SEND_REQUEST: 'ส่งคำขอเข้าร่วม',
  SELL_ALL: 'ดูทั้งหมด',
  EDIT: 'แก้ไข',
  SHARE: 'แชร์',
  MANAGE: 'จัดการ',
  ADD_TO_CALENDAR: 'เพิ่มในปฏิทิน',
  ADD_MEMBER: 'เพิ่มสมาชิก',
  SEND_INVITE: 'ส่งคำเชิญ',
  SEND_INVITE_ALL: 'ส่งคำเชิญแบบกลุ่ม',
  EDIT_MEMBER_INFO: 'แก้ไขข้อมูลสมาชิก',
  ADD_MEMBER_MOBILE: 'เพิ่ม',
  MORE_DETAIL: 'รายละเอียดเพิ่มเติม',
  LOAD_MORE: 'เพิ่มเติม',
  MY_EVENT: 'อีเว้นท์ของฉัน',
  CREATE_NEW_EVENTS: 'สร้างอีเว้นท์ใหม่',
  CHOOSE: 'เลือก',
  HOME: 'หน้าหลัก',
  MY_CLUB: 'คลับของฉัน',
  ACCOUNT: 'บัญชี',
  EDIT_PROFILE: 'แก้ไขข้อมูลส่วนตัว',
  EDIT_PERSONAL_INFO: 'แก้ไขข้อมูลส่วนตัว',
  EDIT_PLACE: 'แก้ไขที่ตั้ง และ สถานที่',
  EDIT_INFO: 'แก้ไขข้อมูล',
  CHANGE_PASSWORD: 'แก้ไขรหัสผ่าน',
  PAYMENT_HISTORY: 'ประวัติการจ่ายเงิน',
  SIGN_IN: 'เข้าสู่ระบบ',
  SIGN_UP: 'สมัครใช้บริการ',
  SIGN_OUT: 'ออกจากระบบ',
  PRICING: 'ราคา',
  JOIN_THIS_EVENT: 'เข้าร่วมอีเว้นท์นี้',
  VIEW_YOUR_PASS: 'ดูบัตรผ่าน',
  MAP: 'แผนที่',
  PLACE: 'ที่ตั้ง',
  PHOTO_GALLERY: 'ห้องภาพ',
  INVITATIONS: 'การเชิญ',
  IMPORT_INVITATION: 'นำเข้าไฟล์ส่งคำเชิญ',
  EXPORT_INVITATION: 'ส่งออกไฟล์ส่งคำเชิญ',
  EXPORT_INVITATION_FAIL: 'ส่งออกไฟล์ล้มเหลว',
  NEW_INVITATION: 'ส่งคำเชิญ',
  TRANSACTIONS: 'รายการ',
  PACKAGE: 'แพคเกจ',
  UPGRADE: 'อัปเกรด',
  UPGRADE_PACKAGE: 'อัปเกรดแพ็กเกจ',
  TICKET_GOODS: 'บัตรผ่านและสินค้า',
  NAME: 'ชื่อ',
  EMAIL_ADDRESS: 'ที่อยู่อีเมล',
  EMAIL: 'อีเมล',
  PASSWORD: 'รหัสผ่าน',
  PLEASE_ENTER_PASSWORD: 'กรุณากรอกรหัสผ่าน',
  FORGOT_PASSWORD: 'ลืมรหัสผ่าน',
  CONFIRM_PASSWORD: 'ยืนยันรหัสผ่าน',
  OLD_PASSWORD: 'รหัสผ่านเดิม',
  NEW_PASSWORD: 'รหัสผ่านใหม่',
  FULL_NAME: 'ชื่อ - นามสกุล',
  PHONE_NUMBER: 'หมายเลขโทรศัพท์',
  PHONE_NUMBER_PLACEHOLDER: '888888888',
  REMEMBER_ME: 'จดจำบัญชีผู้ใช้งานของฉัน',
  CHANNEL: 'ช่องทาง',
  AMOUNT: 'จำนวน',
  DATE: 'วันที่',
  LAST_PAYMENT_DATE: 'วันที่สิ้นสุดการเข้าร่วม',
  EVENT: 'อีเว้นท์',
  TYPE: 'ประเภท',
  CREATED_DATE: 'วันที่สร้าง',
  LAST_UPDATED_DATE: 'วันที่อัพเดตล่าสุด',
  BTN_ON_PUBLISH: 'เปิดเผย',
  BTN_OFF_PUBLISH: 'ไม่เปิดเผย',
  FROM: 'จาก',
  TO: 'ถึง',
  STATUS: 'สถานะ',
  NO_DATA: 'ไม่มีข้อมูล',
  NO_ACCOUNT_SIGN_UP_NOW: 'ยังไม่มีบัญชีผู้ใช้งาน? สมัครใช้บริการเลย.',
  I_AGREE: ['ยอมรับ', 'ข้อตกลงการใช้', 'และ', 'นโยบายความเป็นส่วนตัว'],
  ALREADY_ACCOUNT: 'มีบัญชีผู้ใช้งานอยู่แล้ว? เข้าสู่ระบบได้เลย.',
  RESET: 'รีเซ็ต',
  CHANGE: 'เปลี่ยน',
  SAVE: 'บันทึก',
  SEND: 'ส่ง',
  CLOSE: 'ปิด',
  SEND: 'ส่ง',
  NO_EVENT: 'ไม่มีข้อมูลอีเว้น',
  TRANSACTION_DETAIL: 'รายละเอียดรายการ',
  INVITATION_DETAIL: 'รายละเอียดการเชิญ',
  PAYMENT_HISTORY_DETAIL: 'รายละเอียดการชำระเงิน',
  MESSAGE: 'ข้อความ',
  SIGNATURE: 'ลงชื่อ',
  LANGUAGE: 'ภาษา',
  TRANSACTION_ID: 'หมายเลขรายการ',
  THB: 'บาท',
  ARE_YOUR_GOING_JOIN: 'คุณต้องการเข้าร่วม',
  ON: 'ตอน',
  YES: 'ใช่',
  NO: 'ไม่ใช่',
  ERROR_PASSWORD_INCORRECT: 'Password incorrect',
  ERROR_PASSWORD_OLD_NEW_SAME: 'รหัสผ่านเก่าและรหัสใหม่ไม่ควรเหมือนกัน',
  ERROR_PASSWORD_NOT_MATCH: 'รหัสผ่านยืนยันไม่ถูกต้อง',
  LABEL_INPUT_NAME: 'กรุณาใส่ชื่อ',
  LABEL_INPUT_EMAIL: 'กรุณาใส่อีเมล',
  WARNING_NAME_EMPTY: 'ชื่อไม่ควรใส่ค่าว่าง',
  WARNING_EMAIL_EMPTY: 'อีเมลไม่ควรใส่ค่าว่าง',
  WARNING_MESSAGE_EMPTY: 'ข้อความไม่ควรใส่ค่าว่าง',
  WARNING_AMOUNT_EMPTY: 'จำนวนเงินไม่ควรใส่ค่าว่าง',
  WARNING_AMOUNT_MINIMUM: 'จำนวนเงินขั้นต่ำต้องมากกว่า 0',
  WARNING_EMAIL_FORMAT_INCORRECT: 'รูปแบบอีเมลผิด',
  WARNING_INVITATION_ID_MISSING: 'ไม่พบรหัสคำเชิญ',
  WARNING: 'คำเตือน',
  ERROR: 'เกิดข้อผิดพลาด',
  SUCCESS: 'รายการของคุณสำเร็จแล้ว',
  JOIN_SUCCESS: 'คุณได้เข้าร่วมอีเวนท์สำเร็จแล้ว',
  SAVE_SUCCESS: 'บันทึกสำเร็จ',
  SAVE_FAIL: 'บันทึกล้มเหลว',
  CHANGE_PASSWORD_SUCCESS: 'เปลี่ยนรหัสผ่านสำเร็จ',
  SEND_MESSAGE_SUCCESS: 'ส่งข้อความสำเร็จ',
  SEND_MESSAGE_FAIL: 'ส่งข้อความผิดพลาด',
  SEND_INVITATION_SUCCESS: 'ส่งคำเชิญสำเร็จ',
  SEND_INVITATION_FAIL: 'ส่งคำเชิญล้มเหลว',
  JOIN_INVITATION_FAIL:
    'คุณไม่ได้รับเชิญเข้าร่วมอีเว้นท์ กรุณาติดต่อเจ้าของอีเว้นท์',
  WARNING_WANT_DELETE: 'คุณต้องการลบข้อมูลนี้ใช่หรือไม่',
  DELETE_SUCCESS: 'ลบข้อมูลสำเร็จ',
  DELETE_FAIL: 'ลบข้อมูลไม่สำเร็จ',
  WARNING_UPLOAD_SUCCESS: 'อัพโหลดสำเร็จ',
  WARNING_RESET_PASSWORD_SUCCESS: 'เปลี่ยนรหัสผ่านสำเร็จ',
  WARNING_UPLOAD_FAIL: 'อัพโหลดผิดพลาด',
  WARNING_CHECK_IN: 'คุณต้องการเช็คอินใช่หรือไม่',
  OK: 'ตกลง',
  CANCEL: 'ยกเลิก',
  CHECK_IN: 'เช็คอิน',
  CHECK_IN_SUCCESS: 'เช็คอินสำเร็จ',
  WARNING_CANT_THIS_EVENT: 'คุณไม่สามารถเข้าร่วมอีเว้นนี้ได้',
  WARNING_INVALID_INPUT: 'ข้อมูลไม่ถูกต้อง',
  WARNING_INVALID_PHONE_NUMBER_INPUT: 'ข้อมูลเบอร์โทรไม่ถูกต้อง',
  WARNING_PLEASE_REPLY_MESSAGE: 'กรุณาส่งข้อความ',
  WARNING_PLEASE_INPUT_MESSAGE: 'กรุณากรอกข้อมูลข้อความ',
  WARNING_EXPIRE_JOIN_EVENT: 'หมดเวลาการเข้าร่วมอีเว้นนี้',
  WARNING_MIN_PAYMENT: 'จำนวนเงินชำระขั้นต่ำคือ 20 บาท',
  REPLY_MESSAGE: 'ส่งข้อความ',
  REPLY_MESSAGE_DESCRIPTION:
    "สามารถส่งข้อความและคำอวยพรถึงผู้จัดงาน โดนกดปุ่ม 'ส่ง'",
  SUBMIT: 'ส่งข้อมูล',
  MESSAGE: 'ข้อความ',
  VERIFICATION_CODE: 'Verification Code',
  CONTINUE: 'ดำเนินการต่อ',
  PLEASE_SPECIFY_THE_AMOUNT: 'โปรดระบุจำนวนเงินที่จะเข้าร่วมกิจกรรม',
  LABEL_INFO_TITLE_ENG: 'ชื่อ (English)',
  LABEL_INFO_TITLE_TH: 'ชื่อ (ไทย)',
  LABEL_INFO_DESC_ENG: 'รายละเอียด (English)',
  LABEL_INFO_DESC_TH: 'รายละเอียด (ไทย)',
  LABEL_PLACE_ADDRESS_ENG: 'ที่อยู่ (English)',
  LABEL_PLACE_ADDRESS_TH: 'ที่อยู่ (ไทย)',
  LABEL_PLACE_LAT: 'ละติจูด',
  LABEL_PLACE_LNG: 'ลองจิจูด',
  RECEIVER_NAME: 'ชื่อผู้รับ',
  RECEIVER_EMAIL: 'อีเมล์ผู้รับ',
  ERROR_NOT_LOGIN: 'กรุณาล็อคอินเข้าระบบ',
  ERROR_NOT_OWNER_EVENT:
    'กรุณาล็อคอินเข้าระบบด้วยบัญชีเจ้าของงานเพื่อทำการเช็คอิน',
  ERROR_ALREADY_CHECKIN: 'คุณได้เช็คอินไปแล้ว',
  TRANSACTION_STATUS_FAILED: 'ล้มเหลว',
  TRANSACTION_STATUS_EXPIRED: 'หมดอายุ',
  TRANSACTION_STATUS_PENDING: 'รอดำเนินการ',
  TRANSACTION_STATUS_REVERSED: 'ย้อนกลับ',
  TRANSACTION_STATUS_SUCCESSFUL: 'สำเร็จ',
  TRANSACTION_PAYMENT_CARD: 'บัตรเครดิต',
  TRANSACTION_PAYMENT_INTERNET_BANKING: 'อินเตอร์เน็ตแบงค์กิ้ง',
  TRANSACTION_ORDER_TYPE_CREATED: 'สร้าง',
  TRANSACTION_ORDER_TYPE_PAID: 'จ่าย',
  TRANSACTION_ORDER_TYPE_REFUNDED: 'คืนเงิน',
  TRANSACTION_ORDER_TYPE_JOIN: 'เข้าร่วม',
  TRANSACTION_ORDER_TYPE_CREATE: 'สร้าง',
  TRANSACTION_ORDER_TYPE_UPGRADE: 'อัพเกรด',
  JOIN_TYPE: 'ประเภท',
  PRIVACY: 'ความเป็นส่วนตัว',
  WEBSITE: 'เว็บไซต์',
  FACEBOOK: 'เฟสบุ๊ค',
  PRIVATE: 'ส่วนตัว',
  PUBLIC_VIEW: 'มุมมองสาธารณะ',
  PUBLIC_JOIN: 'เข้าร่วมสาธารณะ',
  CLUB_JOIN: 'สมาคมหรือชมรม',
  PAID_OR_FREE: 'จ่ายหรือฟรี',
  FREE: 'ฟรี',
  PAID: 'จ่าย',
  DISABLED: 'ปิดการใช้งาน',
  ENABLED: 'เปิดการใช้งาน',
  BANNED: 'ห้ามการใช้งาน',
  CLOSED: 'ปิดการใช้งาน',
  PRICE_FOR_THE_PLAN: 'ราคาแพ็กเกจ',
  NUMBER_OF_INVITEES_PER_EVENT: 'จำนวนคนในการเชิญเข้าร่วมอีเว้นท์',
  AVAILABLE_ON_WEBSITE:
    'สามารถใช้งานบนเว็บไซด์และแอปพลิเคชัน (แอปสโตร์และกูเกิล เพลย์)',
  GOOGLE_CALENDAR_MAP: 'ปฏิทินและแผนที่แบบออนไลน์ของกูเกิล',
  QR_CODE_FOR_REGIS: 'คิวอาร์โค้ดสำหรับการลงทะเบียนเข้าร่วมอีเว้นท์',
  UNLIMITED_PHOTO_STORAGE: 'พื้นที่จัดเก็บรูปภาพแบบไม่จำกัด',
  PUSH_EVENT_NOTIFICATION: 'การแจ้งเตือนอีเว้นท์',
  GREETING_MESSAGE: 'ส่งคำอวยพรให้เจ้าของอีเว้นท์',
  SUMMARY_OF_EVENT_PARTICIPANTS: 'สรุปผลข้อมูลผู้เข้าร่วมอีเว้นท์',
  ONLINE_PAYMENT:
    'การชำระเงินออนไลน์ผ่านทางเครดิตการ์ดและอินเตอร์เน็ตแบงค์กิ้ง',
  IMPORT_INVITATION_DETAIL:
    'คุณสามารถส่งคำเชิญได้มากกว่า 1 ท่านในครั้งเดียวโดยผ่านการ import สมาชิกไฟล์ โดยคุณสามารถดูตัวอย่างไฟล์ที่ใช้งานได้ดังนี้',
  IMPORT_INVITATION_DETAIL_NEXT:
    'ทั้งนี้ข้อมูลที่ต้องกรอกในไฟล์นั้นประกอบด้วย ชื่อและอีเมล ดังในตัวอย่างอันล่าง',
  EXAMPLE_TEMPLATE: 'ตัวอย่างไฟล์นามสกุล',
  XLSX: 'xlsx',
  CSV: 'csv',
  ERROR_UPLOAD_CSV_XLSX:
    'เกิดข้อผิดพลาดนามสกุลของไฟล์ต้องเป็น .csv หรือ .xlsx เท่านั้น',
  DOWNLOAD: 'ดาวน์โหลด',
  FILE_EXTENSION: 'ชนิดของไฟล์',
  ACTIONS: 'รายการแก้ไข',
  TABLE_MENU_ACTION: 'รายการแก้ไข',
  RESEND: 'ส่งคำเชิญซ้ำ',
  RESEND_FAIL: 'ส่งคำเชิญซ้ำล้มเหลว',
  RESEND_SUCCESS: 'ส่งคำเชิญซ้ำสำเร็จแล้ว',
  RESENDING: 'กำลังส่งคำเชิญซ้ำ',
  REVOKE: 'ยกเลิกคำเชิญ',
  REVOKING: 'กำลังยกเลิกคำเชิญ',
  REVOKE_FAIL: 'การยกคำเชิญเลิกล้มเหลว',
  REVOKE_SUCCESS: 'ยกเลิกคำเชิญสำเร็จแล้ว',
  ACCEPT: 'รับคำเชิญ',
  ACCEPT_THIS_EVENT: 'รับคำเชิญอีเว้นท์นี้',
  ACCEPT_SUCCESS: 'รับคำเชิญเรียบร้อยแล้ว',
  IMAGE_CROPPER: 'เลือกส่วนรูปภาพ',
  SELECT_PLACE_ON_MAP: 'เลือกจากแผนที่',
  SELECT: 'เลือก',
  UPDATE_INFORMATION: 'โปรดอัปเดตข้อมูลส่วนบุคคลของคุณ',
  PERSONAL_INFORMATION: 'ข้อมูลส่วนบุคคล',
  CLUB_STATUS: 'สถานะ',
  MEMBER: 'สมาชิก',
  ADMINISTRATOR: 'ผู้ดูแลระบบ',
  INVITREE_ACCOUNT: 'สมาชิก invitree',
  NOT_A_MEMBER: 'ยังไม่เป็นสมาชิก',
  DO_NOT_HAVE_ACCOUNT: `ไม่มีบัญชี`,
  ALREADY_HAVE_ACCOUNT: 'มีบัญชีแล้ว',
  NAME_AND_FAMILY_NAME: 'ชื่อ - นามสกุล',
  ENTER_NAME_AND_FAMILY_NAME: 'กรอกชื่อ - นามสกุล',
  NICKNAME1: 'ชื่อเล่น 1',
  ENTER_NICKNAME1: 'กรอกชื่อเล่น 1',
  NICKNAME2: 'ชื่อเล่น 2',
  ENTER_NICKNAME2: 'กรอกชื่อเล่น 2',
  FACULTY: 'คณะ',
  ENTER_FACULTY: 'คณะ วิศวกรรมศาสตร์',
  CURRICULUM: 'หลักสูตร',
  ENTER_CURRICULUM: 'กรอกหลักสูตร',
  CLASS_YEAR: 'รุ่น',
  ENTER_CLASS_YEAR: '',
  CLASS_OF: 'ปีการศึกษา (ปีที่เข้าศึกษา)',
  ENTER_CLASS_OF: 'กรอกปีการศึกษา',
  PHONE_NUMBER: 'เบอร์โทรศัพท์มือถือ',
  ENTER_PHONE_NUMBER: 'กรอกเบอร์โทรศัพท์มือถือ',
  STUDENT_ID: 'รหัสนักศึกษา',
  ENTER_STUDENT_ID: 'กรอกรหัสนักศึกษา',
  MAJOR: 'ภาค',
  ENTER_MAJOR: 'กรอกภาค',
  LINE_ID: 'Line ID',
  ENTER_LINE_ID: 'กรอก Line ID',
  POSITION: 'ตำแหน่ง',
  ENTER_POSITION: 'กรอกตำแหน่งงาน',
  COMPANY_DEPARTMENT: 'บริษัท หรือ หน่วยงาน',
  ENTER_COMPANY_DEPARTMENT: 'กรอก บริษัท หรือ หน่วยงาน',
  BUSINESS_TYPE: 'ลักษณะของธุรกิจ',
  ENTER_BUSINESS_TYPE: 'กรอกลักษณะของธุรกิจ',
  SKILL_EXPERIENCE: 'ทักษะและประสบการณ์',
  ENTER_SKILL_EXPERIENCE: 'กรอกทักษะและประสบการณ์',
  ADD_WORKPLACE: 'เพิ่มสถานที่ทำงาน',
  STILL_WORKING: 'ทำงานอยู่',
  RETIRED: 'ไม่ได้ทำงาน',
  CURRENT_ADDRESS: 'ที่อยู่ปัจจุบัน',
  ENTER_YOUR_CURRENT_ADDRESS: 'กรอกที่อยู่ปัจจุบัน',
  E_MAIL: 'อีเมล์',
  ENTER_YOUR_E_MAIL: 'กรอกอีเมล์',
  FACEBOOK: 'Facebook',
  ENTER_YOUR_FACEBOOK_NAME: 'กรอกชื่อ Facebook',
  SKIP: 'ข้าม',
  SAVE: 'บันทึก',
  PUBLISH: 'สาธารณะ',
  ACTIVITIES: 'กิจกรรม',
  WARNING_CLUB_LOGIN:
    'หากต้องการดูรายละเอียดเพิ่มเติม กรุณาลงชื่อเข้าใช้ด้วยบัญชี invitree',
  WEBSITE: 'เว็บไซต์',
  UPCOMING_EVENTS: 'กำลังจะเริ่ม',
  SEE_THIS_EVENT: 'ดูอีเว้นท์นี้',
  SHARE_ACTIVITY: 'แชร์',
  PAST_EVENTS: 'ผ่านไปแล้ว',
  TICKET: 'ตั๋ว',
  TICKET_PRICE: 'ราคาตั๋ว',
  PRICE: 'ราคา',
  TOTAL_PRICE: 'ราคารวม',
  QUANTITY: 'จำนวน',
  SELECT_PAYTMENT_METHOD: 'เลือกวิธีการชำระเงิน',
  PAYMENT_BY_QR_CODE: 'ชำระเงินด้วย QR Code',
  PATMENT_BY_CREDIT_CARD: 'ชำระเงินด้วยบัตรเครดิต',
  BUY_TICKET: 'ชำระเงิน',
  PLEASE_COMPLETE_PERSONAL_INFORMATION: 'กรุณากรอกข้อมูลให้ครบถ้วน',
  OK: 'ตกลง',
  ACTIVITY_SCHEDULE: 'ตารางกิจกรรม',
  JOIN_THIS_EVENT: 'เข้าร่วมอีเว้นท์นี้',
  ADD_TO_CALENDAR: 'เพิ่มในปฏิทิน',
  ORDER_SUMMARY: 'สรุปคำสั่งซื้อ',
  REVIEW_ORDER_SUMMARY: 'ตรวจสอบคำสั่งซื้อ',
  PAYMENT: 'การชำระเงิน',
  ITEM: 'รายการ',
  FEE: 'ค่าธรรมเนียม',
  SUBTOTAL: 'ยอดรวม',
  TOTAL: 'ยอดรวม',
  SERVICE_CHARGE: 'ค่าบริการ',
  VAT: 'ภาษี 7%',
  GRAND_TOTAL: 'รวมทั้งหมด',
  WARNING_REFUND_CONDITION:
    ' ผู้ซื้อได้ทำความเข้าใจและรับรู้ถึงสิทธิ์ในการไม่คืนเงินในกรณีที่ท่านมิได้มารับสินค้า หรือบริการหากท่านได้ชำระค่าสินค้า และค่าบริการแก่ทางบริษัทฯเรียบร้อยแล้ว',
  BACK_TO_EDIT: 'กลับไป',
  CONFIRM: 'ยืนยัน',
  MEMBER_NAME: 'ชื่อสมาชิก',
  REQUESTER_NAME: 'ผู้ขอเข้าเป็นสมาชิก',
  WARNING_MEMBER_APPROVE: 'คุณแน่ใจหรือไม่ว่าจะอนุมัติคำขอนี้?',
  APPROVE: 'อนุมัติคำขอ',
  WARNING_MEMBER_REJECT: 'คุณแน่ใจหรือไม่ว่าจะปฏิเสธคำขอนี้?',
  REJECT: 'ปฎิเสธคำขอ',
  REQUEST_TO_CLUB_SUCCESS: 'คำขอได้ทำการส่งไปยังผู้ดูแลแล้ว กรุณารออีเมล์ตอบกลับ',
  REQUEST_TO_CLUB_NO_SURNAME: 'กรุณากรอกนามสกุล',
  REINVITE_CLUB_MEMBER: 'คุณแน่ใจว่าต้องการเชิญสมาชิกอีกครั้งใช่หรือไม่?',
  REINVITE_SUCCESS: 'ดำเนินการเชิญสมาชิกอีกครั้งสำเร็จ',
  SIGNIN_INFO_KMITL: 'สมาคมศิษย์เก่าคณะวิศวกรรมศาสตร์ สจล.\n สงวนสิทธิ์การเข้าร่วมเฉพาะศิษย์เก่า\n ศิษย์ปัจจุบัน และผู้ที่เคยศึกษาในคณะวิศวกรรมศาสตร์เท่านั้น',
  SIGNUP_INFO_KMITL: 'กรุณากรอกชื่อและอีเมลเพื่อขอเข้าร่วมสมาคมศิษย์เก่าคณะวิศวกรรมศาสตร์ สจล. คณะผู้ดูแลระบบจะทำการส่งอีเมลเพื่อติดต่อท่านเพื่อยืนตัวตนอีกครั้ง',
  ADDITIONAL_INFORMATION_CLUB_TITLE: 'ข้อมูลประกอบการพิจารณา (เท่าที่กรอกได้)',
  ADDITIONAL_INFORMATION_CLUB_REQUEST: 'กรุณากรอก\n - ชื่อ-นามสกุล ขณะศึกษา\n- รหัสนักศึกษา\n- ปี พ.ศ.ที่เข้าศึกษา\n- ภาควิชา\n- อาจารย์โปรเจค\n- เพื่อนในรุ่นที่อ้างอิงได้',
  EMAIL_NOT_VALID: 'ที่อยู่อีเมลไม่ถูกต้อง',
  POSITION: 'ตำแหน่ง',
  OWNER: 'เจ้าของชมรม',
  ADMIN: 'แอดมิน',
  PHOTO_GALLERY: 'คลังภาพ',
  SEE_MORE_PHOTO: 'ดูรูปเพิ่มเติม',
  PLACE: 'ที่ตั้ง',
  MAP: 'แผนที่',
  QR_CODE_PAYMENT: 'วิธีชำระเงินด้วย QR Code',
  SCAN_AND_PAY_DESKTOP: 'สแกนชำระเงินด้วยแอปพลิเคชันธนาคารใดก็ได้',
  SAVE_AND_PAY_MOBILE: 'บันทึกภาพ QR Code นี้ เพื่อชำระผ่านแอปพลิเคชันธนาคารใดก็ได้',
  SAVE_QR_CODE: 'บันทึกภาพ QR Code',
  COMPLETED: 'เสร็จสิ้น',
  HOW_TO_PAY_WITH: 'วิธีชำระเงินด้วย',
  YEAR: 'ปี',
  MONTH: 'เดือน',
  UNTIL_NOW: 'ปัจจุบัน',
  WARNING_REGISTER_KMITL:
    'กรุณากรอกข้อมูลให้ครบถ้วนเพื่อเข้าร่วมสมาคมศิษย์เก่าคณะวิศวกรรมศาสตร์',
  WARNING_PUBLISH: 'ข้อมูลจะถูกเปิดเผยเฉพาะในกลุ่มเท่านั้น',
  ERROR_REGISTER_PHONE:
    'ใส่เฉพาะตัวเลขเรียงติดกัน โดยไม่มีเครื่องหมายหรือเว้นวรรค',
  ERROR_REGISTER_EMAIL: 'กรุณากรอกอีเมลให้ถูกต้อง',
  ERROR_REGISTER_PASSWORD: 'กรุณากรอกรหัสผ่านให้ถูกต้อง',
  SUCCESS_REGISTER_KMITL: 'บันทึกข้อมูลเรียบร้อยแล้ว',
  LIST_ALL: 'ทั้งหมด',
  LIST_NAME: 'ชื่อ',
  LIST_LAST_NAME: 'นามสกุล',
  LIST_NICKNAME: 'ชื่อเล่น',
  LIST_CLASS_YEAR: 'รุ่น',
  LIST_CLASS_OF: 'ปีการศึกษา',
  LIST_MAJOR: 'ภาค',
  ENTER_FIRST_LOGIN_USER:
    'เข้าสู่ระบบครั้งแรก ใส่ชื่อ-นามสกุล ที่ใช้ขณะเรียน สจล.',
  ENTER_FIRST_LOGIN_STUDENT_ID:
    'ใส่เฉพาะตัวเลขเรียงติดกัน โดยไม่มีเครื่องหมายหรือเว้นวรรค',
  WARNING_DELETE_MEMBER: 'ตุณแน่ใจที่จะลบสมาชิกนี่ใช่หรือไม่',
  ASSIGN_ADMIN: 'ตั้งเป็นผู้ดูแลระบบ',
  REMOVE_ADMIN: 'ยกเลิกผู้ดูแลระบบ',
// ========== Message in Club section ==============

SELECT_MAJOR: 'เลือกสาขา',

// ========== Error return form Backend ==============

USER_PROFILE_INFO_ALREADY_EXIST: 'มีข้อมูลผู้ใช้งานนี้อยู่แล้ว',
INVITREE_USERNAME_ALREADY_EXIST: 'มีข้อมูลผู้ใช้งานในระบบอินไวท์ทรีนี้อยู่แล้ว',

// ========== Message in BUY TICKET PAGE ==============
TICKET_DETAIL: 'รายละเอียดของบัตรเข้างาน',
GOODS_AND_SOUVENIR: 'สินค้าและของที่ระลึก',
DELIVERY: 'การจัดส่ง',
SELECT_PAYMENT: 'วิธีการจ่ายเงิน',
DELIVERY_ADDRESS: 'ที่อยู่สำหรับจัดส่ง',
ADDRESS: 'ที่อยู่',
TEL: 'โทร',
PAY_NOW: 'ชำระทันที',
QR_EXPIRE: 'กรุณาชำระเงินภายใน',
WARNING_GEN_QR_FAIL: "ทำการขอคิวอาร์โค้ดไม่สำเร็จ",
PREVIEW_IMG: 'รูปตัวอย่าง'
}
