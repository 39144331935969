import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import swal from 'sweetalert'

import actions from './actions'
import appActions from '../app/actions'
import {
  notificationInfo,
  notificationSuccess,
  notificationError,
} from '../../utils/notification'

import {
  delay,
  getEventTotalSum,
  getPackageListForUpgrade,
  listInvitationsAPI,
  sendInvitationAPI,
  resendInvitationAPI,
  revokeInvitationAPI,
  exportTransactionsAPI,
} from '../../utils/api'
import ModalHandler from '../../utils/modal'

const getLang = function*() {
  const app = yield select(state => state.app)
  return {
    lang: app.lang,
    locale: app.locale[app.lang],
  }
}

export function* sendInvitation() {
  yield takeEvery(actions.ON_SEND_INVITATION, function*(action) {
    // yield console.log(action)
    const { locale } = yield call(getLang)
    const data = yield call(sendInvitationAPI, action.payload)
    if (data.code === 'OK') {
      yield swal(locale.SUCCESS, locale.SEND_INVITATION_SUCCESS, 'success')
      yield ModalHandler.closeModal('modalInvitationNew')
      yield put({
        type: actions.ON_LIST_INVITATIONS,
        payload: {
          id: action.payload.event_id,
          callback: () => action.callback(),
        },
      })
    } else {
      yield swal(locale.ERROR, locale.SEND_INVITATION_FAIL, 'error')
      yield action.callback()
    }
  })
}

export function* importInvitation() {
  yield takeEvery(actions.ON_IMPORT_INVITATION, function*(action) {
    const { locale } = yield call(getLang)
    const data = yield call(sendInvitationAPI, action.payload)

    if (data.code === 'OK') {
      yield swal(locale.SUCCESS, locale.SEND_INVITATION_SUCCESS, 'success')
      yield ModalHandler.closeModal('modalImportInvitation')
      yield put({
        type: actions.ON_LIST_INVITATIONS,
        payload: {
          id: action.payload.event_id,
          search: action.payload.search,
          callback: () => action.callback(),
        },
      })
    } else {
      yield swal(locale.ERROR, data.text.error, 'error')
      yield action.callback()
    }
  })
}

export function* exportInvitation() {
  yield takeEvery(actions.ON_EXPORT_INVITATION, function*(action) {
    const { locale } = yield call(getLang)
    const response = yield call(
      exportTransactionsAPI,
      action.payload.event_id,
      action.payload.extension
    )

    if (response.code === 'OK') {
      yield ModalHandler.closeModal('modalExportInvitation')

      let extension = action.payload.extension

      let newBlob = new Blob([response.data])
      let url = window.URL.createObjectURL(newBlob)

      let link = document.createElement('a')
      link.style.display = 'none'
      link.setAttribute('href', url)
      link.setAttribute('download', `invitations.${extension}`)

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      setTimeout(() => {
        window.URL.revokeObjectURL(url)
      }, 100)
    } else {
      yield swal(locale.ERROR, locale.EXPORT_INVITATION_FAIL, 'error')
    }

    yield action.callback()
  })
}

export function* revokeInvitation() {
  yield takeEvery(actions.ON_REVOKE_INVITATION, function*(action) {
    const { locale } = yield call(getLang)
    yield notificationInfo(locale.MESSAGE, locale.REVOKING)

    const data = yield call(revokeInvitationAPI, action.payload.data.id)
    if (data.code === 'OK') {
      yield notificationSuccess(locale.SUCCESS, locale.REVOKE_SUCCESS)
    } else {
      yield notificationError(locale.ERROR, locale.REVOKE_FAIL)
    }
  })
}

export function* resendInvitation() {
  yield takeEvery(actions.ON_RESEND_INVITATION, function*(action) {
    const { locale } = yield call(getLang)
    yield notificationInfo(locale.MESSAGE, locale.RESENDING)

    const data = yield call(resendInvitationAPI, action.payload.data.id)
    if (data.code === 'OK') {
      yield notificationSuccess(locale.SUCCESS, locale.RESEND_SUCCESS)
    } else {
      yield notificationError(locale.ERROR, locale.RESEND_FAIL)
    }
  })
}

export function* listInvitations() {
  yield takeEvery(actions.ON_LIST_INVITATIONS, function*(action) {
    const res = yield call(
      listInvitationsAPI,
      action.payload.id,
      action.payload.search
    )
    const res2 = yield call(getEventTotalSum, action.payload.id)
    const res3 = yield call(getPackageListForUpgrade, action.payload.id)
    yield call(delay, 300)
    if (res.code === 'OK') {
      yield put({
        type: actions.SET_LIST_INVITATIONS,
        payload: {
          invitationList: res.data,
          totalSummary: res2.data,
        },
      })
      if (res3.code === 'OK') {
        yield put({
          type: appActions.SET_PACKAGE_LIST_UPGRADE,
          payload: res3.data.packages || [],
        })
      }
      yield action.payload.callback()
    }
  })
}

export function* onShowInvitationDetail() {
  yield takeEvery(actions.ON_SHOW_INVITATION_DETAIL, function*(action) {
    // yield console.log(action)
    yield put({
      type: actions.SET_INVITATION_DETAIL,
      payload: action.payload,
    })

    yield ModalHandler.openModal('modalInvitationDetail')
  })
}

export default function* rootSaga() {
  yield all([
    fork(importInvitation),
    fork(exportInvitation),
    fork(sendInvitation),
    fork(revokeInvitation),
    fork(resendInvitation),
    fork(listInvitations),
    fork(onShowInvitationDetail),
  ])
}
