export function payCreditCard(cardInformation, payment_method) {
  if (cardInformation && payment_method === 'credit_card') {
    const { Omise } = window
    const publicKey = process.env.GATSBY_OMISE_PUBLICKEY
    Omise.setPublicKey(publicKey)
    return new Promise(resolve => {
      Omise.createToken('card', cardInformation, function(
        statusCode,
        response
      ) {
        if (statusCode === 200) {
          resolve({
            code: 'OK',
            message: '',
            data: response.id,
          })
        } else {
          resolve({
            code: 'FAIL',
            message: response.message,
            data: null,
          })
        }
      })
    })
  } else {
    return new Promise(resolve => {
      resolve({
        code: 'OK',
        message: '',
        data: '',
      })
    })
  }
}
