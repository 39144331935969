import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import swal from 'sweetalert'
// import ModalHandler from '../../utils/modal'
import Cookies from 'js-cookie'
import { navigate } from 'gatsby-link'
import actions from './actions'

import {
  createOrderAPI,
  getEventDetail,
  getOrderAPI,
  getPackageDetail,
  getProfileTransactions,
  orderChargeAPI,
  postBlessing,
} from '../../utils/api'
import ModalHandler from '../../utils/modal'
import { payCreditCard } from '../../utils/omise'
// import Cookies from 'js-cookie'
// import { navigate } from 'gatsby-link'

const getLang = function* () {
  const app = yield select(state => state.app)
  return {
    lang: app.lang,
    locale: app.locale[app.lang],
  }
}

export function* onAutoSubmit() {
  yield takeEvery(actions.ON_AUTO_SUBMIT, function* (action) {
    const { locale } = yield call(getLang)
    const resPackage = yield call(getPackageDetail, action.payload.package_id)

    if (resPackage.code === 'OK') {
      let currency = resPackage.data.currency || 'thb'
      let payloadCreateOrder = {}

      if (action.payload.order_type === 'buy_ticket') {
        payloadCreateOrder = {
          order_type: action.payload.order_type,
          package_id: parseInt(action.payload.package_id),
          event_id: parseInt(action.payload.event_id),
          amount: parseFloat(action.payload.amount),
          currency: currency,
        }
      } else {
        payloadCreateOrder = {
          order_type: action.payload.order_type,
          package_id: parseInt(action.payload.package_id),
          event_id: parseInt(action.payload.event_id),
        }
      }

      const data = yield call(createOrderAPI, payloadCreateOrder)

      if (data.code === 'OK') {
        yield put({ type: actions.SET_PAYMENT, value: data })
        yield put({ type: actions.ON_PAYMENT, payload: action.payload })
      } else {
        yield swal(locale.ERROR, data.text.message || data.text, 'error').then(
          goToHome
        )
        yield put({ type: actions.SET_PAYMENT, value: false })
      }
    } else {
      yield swal(locale.ERROR, resPackage.text, 'error')
    }
  })
}

export function* onPaymentSubmit() {
  yield takeEvery(actions.ON_PAYMENT, function* (action) {
    const { locale } = yield call(getLang)
    const data = yield select(state => state.payment.createOrderStatus)
    const resPayment = yield call(
      payCreditCard,
      action.payload.cardInformation,
      action.payload.payload.payment_method
    )

    if (resPayment.code === 'OK') {
      if (action.payload.payload.charge_parameters) {
        action.payload.payload.charge_parameters.charge_token = resPayment.data
      }

      const data2 = yield call(
        orderChargeAPI,
        data.data.id,
        action.payload.payload
      )
      if (data2.code === 'OK') {
        if (
          data2.data &&
          action.payload.payload.payment_method === 'internet_banking'
        ) {
          if (data2.data.authorize_uri) {
            yield (window.location = data2.data.authorize_uri)
          }
        } else {
          yield navigate(`/charge-result/?id=${data.data.id}`)
        }
      } else {
        yield swal(locale.SUCCESS, data2.text, 'error').then(goToHome)
      }
    } else {
      yield swal(locale.ERROR, resPayment.message, 'error').then(goToHome)
    }
  })
}

export function* onChargeSubmit() {
  yield takeEvery(actions.ON_CHARGE, function* (action) {
    yield call(orderChargeAPI, action.orderID, action.payload)
    // yield console.log(action, data)
  })
}

export function* onPaymentHistoryLoad() {
  yield takeEvery(actions.ON_PAYMENT_HISTORY_LOAD, function* (action) {
    const res = yield call(getProfileTransactions, action.payload.search)
    // yield console.log(action,res);
    if (res.code === 'OK') {
      yield put({
        type: actions.SET_PAYMENT_HISTORY_LIST,
        payload: res.data,
      })
    }
    yield action.payload.callback()
  })
}

export function* onPaymentHistoryDetail() {
  yield takeEvery(actions.ON_PAYMENT_HISTORY_DETAIL, function* (action) {
    // yield console.log(action)
    yield put({
      type: actions.SET_PAYMENT_HISTORY_DETAIL,
      payload: action.payload,
    })
    yield ModalHandler.openModal('modalPaymentHistoryDetail')
  })
}

function goToHome() {
  navigate(`/`)
}

export function* onPaymentGetDetail() {
  yield takeEvery(actions.ON_PAYMENT_GET_DETAIL, function* (action) {
    // yield console.log(action)
    const { locale } = yield call(getLang)
    if (action.payload.id) {
      const res = yield call(getOrderAPI, action.payload.id)
      if (res.code === 'OK') {
        if (res.data.status === 'paid') {
          const blessing = yield Cookies.get('blessing')
          if (blessing) {
            yield call(postBlessing, blessing, res.data.invitation_id)
            yield Cookies.remove('blessing')
          }
          const event_id = res.data.event_id
          if (res.data.order_type === 'buy_ticket') {
            yield swal(locale.SUCCESS, locale.JOIN_SUCCESS, 'success').then(
              function () {
                // GO DETAIL
                navigate(`/event-detail/?id=${event_id}`)
              }
            )
          } else {
            yield swal(locale.SUCCESS, locale.SAVE_SUCCESS, 'success').then(
              function () {
                // GO EDIT DETAIL
                navigate(`/event-detail/edit/?id=${event_id}`)
              }
            )
          }
          // if (res.data.order_type === 'buy_ticket' && res.data.amount === '0') {
          //   yield swal(locale.SUCCESS, locale.SAVE_SUCCESS, 'success').then(goToHome)
          // } else {
          //   yield swal(locale.SUCCESS, locale.SAVE_SUCCESS, 'success').then(goToHome)
          // }
        } else {
          yield swal(locale.ERROR, locale.SAVE_FAIL, 'error').then(goToHome)
        }
      } else {
        yield swal(locale.ERROR, res.text, 'error').then(goToHome)
      }
    } else {
      yield swal(locale.ERROR, locale.SAVE_FAIL, 'error').then(goToHome)
    }
  })
}

export function* onPaymentLoad() {
  yield takeEvery(actions.ON_PAYMENT_LOAD, function* (action) {
    const resPackage = yield call(getPackageDetail, action.payload.package_id)
    let name = '-'
    let logo = ''
    if (action.payload.event_id) {
      const resDetail = yield call(getEventDetail, action.payload.event_id)
      name = resDetail.data.original.title_en || '-'
      logo = resDetail.data.original.logo || ''
    }
    if (resPackage.code === 'OK') {
      let currency = resPackage.data.currency || 'thb'

      let price = resPackage.data.price || 0
      const payloadPackage = {
        name,
        packageName: resPackage.data.name_en || '',
        logo,
        currency: currency,
        price: price,
      }
      yield put({
        type: actions.SET_PAYMENT_ONLOAD,
        payload: payloadPackage,
      })
      
      const buyPackageDetail = yield select(
        state => state.home.buyPackageDetail
      )

      const buyTicketDetail = yield select(
        state => state.payment.buyTicketDetail
      )
      let order_type = action.payload.order_type
      //let amount = order_type === 'buy_ticket' ? buyTicketDetail.amount : price
      let payloadCreateOrder = {}
      if (order_type === 'buy_package') {
        payloadCreateOrder = {
          order_type: order_type,
          package_id: parseInt(action.payload.package_id),
          event_id: parseInt(action.payload.event_id),
          currency: currency,
        }
      }
      if (order_type === 'buy_ticket') {
        payloadCreateOrder = {
          order_type: order_type,
          package_id: parseInt(action.payload.package_id),
          event_id: parseInt(action.payload.event_id),
          currency: currency,
          goods: buyTicketDetail.goods,
          delivery: buyTicketDetail.delivery
        }
      }

      const data = yield call(createOrderAPI, payloadCreateOrder)
      if (data.code === 'OK') {
        yield put({ type: actions.SET_PAYMENT, value: data })
        yield action.payload.callBack()
      } else {
        const { locale } = yield call(getLang)
        yield swal(locale.ERROR, data.text.message || data.text, 'error').then(
          goToHome
        )
        yield put({ type: actions.SET_PAYMENT, value: false })
      }
    } else {
      const { locale } = yield call(getLang)
      yield swal(locale.ERROR, resPackage.text, 'error')
    }
  })
}

export function* onSubmitAmountTicket() {
  yield takeEvery(actions.ON_SUBMIT_BUT_TICKET, function* (action) {
    // yield console.log(action)
    yield Cookies.set('blessing', action.payload.blessing)
    yield put({
      type: actions.SET_BUT_TICKET_DETAIL,
      payload: action.payload,
    })
    const { package_id, type, event_id, join_type } = action.payload
    const joinTypeParams = `&join_type=${join_type || '-'}`
    yield navigate(
      `/payment/?package_id=${package_id}${joinTypeParams}&type=${type}&event_id=${event_id}`
    )
  })
}

export default function* rootSaga() {
  yield all([
    fork(onAutoSubmit),
    fork(onPaymentSubmit),
    fork(onChargeSubmit),
    fork(onPaymentHistoryDetail),
    fork(onPaymentHistoryLoad),
    fork(onPaymentGetDetail),
    fork(onPaymentLoad),
    fork(onSubmitAmountTicket),
  ])
}
