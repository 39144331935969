const actions = {
  RESET_ALL: 'RESET_HOME_ALL',
  SET_HOME_DATA: 'SET_HOME_DATA',
  SET_MY_EVENTS_DATA: 'SET_MY_EVENTS_DATA',
  SET_HOME_LOGIN_DATA: 'SET_HOME_LOGIN_DATA',
  SET_UPCOMING_DATA: 'SET_HOME_UPCOMING_DATA',
  SET_JOIN_DATA: 'SET_HOME_JOIN_DATA',
  SET_HISTORY_DATA: 'SET_HOME_HISTORY_DATA',
  SET_PUBLIC_DATA: 'SET_HOME_PUBLIC_DATA',
  SET_MY_EVENT_ALL_DATA: 'SET_HOME_MY_EVENT_ALL_DATA',
  SET_EVENT_DETAIL_DATA: 'SET_HOME_EVENT_DETAIL_DATA',
  SET_EVENT_DETAIL_ORIGINAL_DATA: 'SET_HOME_EVENT_DETAIL_ORIGINAL_DATA',
  SET_EVENT_DETAIL_IMAGE_COVER: 'SET_HOME_EVENT_DETAIL_IMAGE_COVER',
  SET_SHARE_ID: 'SET_HOME_SHARE_ID',
  ADD_PHOTO_GALLERY: 'ADD_HOME_EVENT_DETAIL_PHOTO_GALLERY',
  ADD_LOGO_IMAGE: 'ADD_HOME_LOGO_IMAGE',
  DELETE_PHOTO_GALLERY: 'DELETE_HOME_PHOTO_GALLERY',
  DELETE_IMAGE_COVER: 'DELETE_HOME_IMAGE_COVER',
  DELETE_IMAGE_PLACE_COVER: 'DELETE_HOME_IMAGE_PLACE_COVER',
  RESET_DETAIL: 'RESET_HOME_EVENT_DETAIL',
  SET_NEW_REPLY_MESSAGE: 'HOME_SET_NEW_REPLY_MESSAGE',
  SET_ASSIGN_REPLY_MESSAGE_MORE: 'HOME_SET_ASSIGN_REPLY_MESSAGE_MORE',
  SET_EXPIRE: 'SET_HOME_EXPIRE',
  SET_SEARCH_LOAD: 'SET_HOME_SEARCH_LOAD',
  SET_SEARCH_DATA: 'SET_HOME_SEARCH_DATA',
  SET_GOODS_DELIVERY_BY_EVENT: 'SET_GOODS_DELIVERY_BY_EVENT',
  SET_BUY_PACKAGE_DETAIL: 'SET_BUY_PACKAGE_DETAIL',

  onReset: () => ({ type: actions.RESET_DETAIL }),

  onResetAll: () => ({
    type: actions.RESET_ALL,
  }),

  ON_LOAD: 'HOME_ON_LOAD',
  onLoad: () => ({
    type: actions.ON_LOAD,
  }),

  ON_LOAD_HOME: 'HOME_ON_LOAD_HOME',
  onLoadHomeLogin: () => ({
    type: actions.ON_LOAD_HOME,
  }),

  ON_SHARE: 'HOME_EVENT_ON_SHARE',
  onShare: id => ({
    type: actions.ON_SHARE,
    id,
  }),
  ON_DETAIL: 'HOME_EVENT_ON_DETAIL',
  onGoDetail: id => ({
    type: actions.ON_DETAIL,
    id,
  }),
  ON_JOIN: 'HOME_EVENT_ON_JOIN',
  onJoinFree: id => ({
    type: actions.ON_JOIN,
    id,
  }),
  ON_ADD_CALENDAR: 'HOME_ON_ADD_CALENDAR',
  onAddCalendar: (id, platform) => ({
    type: actions.ON_ADD_CALENDAR,
    id,
    platform,
  }),
  ON_JOIN_EVENT: 'HOME_ON_JOIN_EVENT',
  onJoinEvent: id => ({
    type: actions.ON_JOIN_EVENT,
    id,
  }),
  ON_VIEW_YOUR_PASS: 'HOME_ON_VIEW_YOUR_PASS',
  onViewYourPass: (id, invitation_id) => ({
    type: actions.ON_VIEW_YOUR_PASS,
    id,
    invitation_id,
  }),
  ON_LOAD_MY_EVENT: 'HOME_ON_LOAD_MY_EVENT',
  onLoadMyEvent: () => ({
    type: actions.ON_LOAD_MY_EVENT,
  }),

  ON_CHOOSE_MY_EVENT: 'HOME_ON_CHOOSE_MY_EVENT',
  onChooseEvent: (id, typeBuy, eventID, joinType) => ({
    type: actions.ON_CHOOSE_MY_EVENT,
    id,
    typeBuy,
    eventID,
    joinType
  }),

  ON_CHOOSE_MY_EVENT_FOR_FREE: 'HOME_ON_CHOOSE_MY_EVENT_FOR_FREE',
  onChooseEventForFree: (id, typeBuy, eventID) => ({
    type: actions.ON_CHOOSE_MY_EVENT_FOR_FREE,
    id,
    typeBuy,
    eventID,
  }),

  ON_EDIT_MY_EVENT: 'HOME_ON_EDIT_MY_EVENT',
  onEditEvent: id => ({
    type: actions.ON_EDIT_MY_EVENT,
    id,
  }),

  ON_MANAGE_MY_EVENT: 'HOME_ON_MANAGE_MY_EVENT',
  onManageEvent: (id, payload) => ({
    type: actions.ON_MANAGE_MY_EVENT,
    id,
    payload,
  }),

  ON_LOAD_UPCOMING: 'HOME_ON_LOAD_UPCOMING',
  onLoadUpcoming: () => ({
    type: actions.ON_LOAD_UPCOMING,
  }),

  ON_LOAD_JOIN: 'HOME_ON_LOAD_JOIN',
  onLoadJoin: () => ({
    type: actions.ON_LOAD_JOIN,
  }),

  ON_LOAD_HISTORY: 'HOME_ON_LOAD_HISTORY',
  onLoadHistory: () => ({
    type: actions.ON_LOAD_HISTORY,
  }),

  ON_LOAD_PUBLIC: 'HOME_ON_LOAD_PUBLIC',
  onLoadPublic: () => ({
    type: actions.ON_LOAD_PUBLIC,
  }),

  ON_LOAD_SEARCH: 'HOME_ON_LOAD_SEARCH',
  onLoadSearch: payload => ({
    type: actions.ON_LOAD_SEARCH,
    payload,
  }),

  ON_LOAD_MY_EVENT_ALL: 'HOME_ON_LOAD_MY_EVENT_ALL',
  onLoadMyEventALl: () => ({
    type: actions.ON_LOAD_MY_EVENT_ALL,
  }),

  ON_LOAD_DETAIL: 'HOME_ON_LOAD_DETAIL',
  onLoadDetail: (id, flag, invitation_id, invitation_pin) => ({
    type: actions.ON_LOAD_DETAIL,
    id,
    flag,
    invitation_id,
    invitation_pin,
  }),

  ON_DETAIL_SUMBIT: 'HOME_ON_DETAIL_SUMBIT',
  onDetailSubmit: payload => ({
    type: actions.ON_DETAIL_SUMBIT,
    payload,
  }),

  ON_DETAIL_DELETE_IMAGE_GALLERY: 'HOME_ON_DETAIL_DELETE_IMAGE_GALLERY',
  onDeletePhotoGallery: (index1, index2, index3, id, fname) => ({
    type: actions.ON_DETAIL_DELETE_IMAGE_GALLERY,
    index1,
    index2,
    index3,
    id,
    fname,
  }),
  ON_DETAIL_DELETE_IMAGE_COVER: 'HOME_ON_DETAIL_DELETE_IMAGE_COVER',
  onDeleteDetailImageCover: id => ({
    type: actions.ON_DETAIL_DELETE_IMAGE_COVER,
    id,
  }),
  ON_DETAIL_DELETE_IMAGE_PLACE: 'HOME_ON_DETAIL_DELETE_IMAGE_PLACE',
  onDeleteDetailImagePlace: id => ({
    type: actions.ON_DETAIL_DELETE_IMAGE_PLACE,
    id,
  }),
  ON_CHANGE_EVENT_DETAIL_IMAGE_COVER:
    'HOME_ONC_CHANGE_EVENT_DETAIL_IMAGE_COVER',
  onChangeImageCover: data => ({
    type: actions.ON_CHANGE_EVENT_DETAIL_IMAGE_COVER,
    data,
  }),
  ON_ADD_PHOTO_GALLERY: 'HOME_ON_ADD_PHOTO_GALLERY',
  onAddPhotoGallery: data => ({
    type: actions.ON_ADD_PHOTO_GALLERY,
    data,
  }),
  ON_ADD_LOGO_IMAGE: 'HOME_ON_ADD_LOGO_IMAGE',
  onAddLogoImage: data => ({
    type: actions.ON_ADD_LOGO_IMAGE,
    data,
  }),
  SUBMIT_REPLY_MESSAGE: 'EVENT_DETAIL_SUBMIT_REPLY_MESSAGE',
  onSubmitReplyMessage: (payload, callback) => ({
    type: actions.SUBMIT_REPLY_MESSAGE,
    payload,
    callback,
  }),
  CHECK_IN_POST_DATA: 'HOME_CHECK_IN_POST_DATA',
  onCheckInPostData: payload => ({
    type: actions.CHECK_IN_POST_DATA,
    payload,
  }),
  ON_LOAD_REPILES_EVENT: 'HOME_ON_LOAD_REPILES_EVENT',
  onLoadRepilesEvent: payload => ({
    type: actions.ON_LOAD_REPILES_EVENT,
    payload,
  }),

  ON_ACCEPT_INVITATION: 'ON_ACCEPT_INVITATION',
  onAcceptInvitation: (invitation_id, invitation_pin, callback) => ({
    type: actions.ON_ACCEPT_INVITATION,
    invitation_id,
    invitation_pin,
    callback,
  }),
  ON_LOAD_MY_CLUB_ACTIVITY: 'ON_LOAD_MY_CLUB_ACTIVITY',
  onLoadMyClubActivity: () => ({
    type: actions.ON_LOAD_MY_CLUB_ACTIVITY,
  }),

  GET_GOODS_DELIVERY_BY_EVENT: 'GET_GOODS_DELIVERY_BY_EVENT',
  onLoadGoodsDelivery: (eventID, callback) => ({
    type: actions.GET_GOODS_DELIVERY_BY_EVENT,
    eventID,
    callback
  }),
}

export default actions
