import actions from './actions'

const initState = {
  totalSummary: {
    event: {
      logo: '',
      title_en: '',
      title_th: '',
    },
    package: {
      name_en: '',
      name_th: '',
      currency: 'thb',
    },
    invitation_count: 0,
    joined_count: 0,
    max_invitations: 0,
    total_amount: '0',
  },
  invitationList: [],
  invitationDetail: {
    id: 0,
    receiver_name: '',
    receiver_email: '',
    created_at: '',
    status: '',
  },
}

export default function homeReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_LIST_INVITATIONS:
      return {
        ...state,
        invitationList: action.payload.invitationList,
        totalSummary: action.payload.totalSummary,
      }
    case actions.SET_INVITATION_DETAIL:
      return {
        ...state,
        invitationDetail: action.payload,
      }
    default:
      return state
  }
}
