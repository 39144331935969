import actions from './actions'

const initState = {
  createOrderStatus: false,
  historyList: [],
  historyDetail: {
    id: 1,
    created_at: '2019-01-21T00:35:05.318363Z',
    event_name: '',
    type: '',
    payment_method: '',
    amount: '',
    status: '',
    currency: 'thb',
  },
  paymentDetail: {
    name: '-',
    packageName: '-',
    logo: '',
    currency: 'THB',
    price: 0,
  },
  buyTicketDetail: null,
}

export default function homeReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_PAYMENT:
      return {
        ...state,
        createOrderStatus: action.value,
      }
    case actions.SET_PAYMENT_HISTORY_DETAIL:
      return {
        ...state,
        historyDetail: action.payload,
      }
    case actions.SET_PAYMENT_HISTORY_LIST:
      return {
        ...state,
        historyList: action.payload.transactions,
      }
    case actions.SET_PAYMENT_ONLOAD:
      return {
        ...state,
        paymentDetail: action.payload,
      }
    case actions.SET_BUT_TICKET_DETAIL:
      return {
        ...state,
        buyTicketDetail: action.payload,
      }
    default:
      return state
  }
}
