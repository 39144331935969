const actions = {
  TOOGLE_SEARCH_CRITERIA: 'TOOGLE_SEARCH_CRITERIA',
  VALIDATE_SEARCH_CRITERIA: 'VALIDATE_SEARCH_CRITERIA',
  UPDATE_DATE_RANGE_CRITERIA: 'UPDATE_DATE_RANGE_CRITERIA',
  UPDATE_COLUMN_RANGE_CRITERIA: 'UPDATE_COLUMN_RANGE_CRITERIA',
  UPDATE_WINDOW_SIZE: 'UPDATE_WINDOW_SIZE',
  TOGGLE_DROPDOWN_INPUT: 'TOGGLE_DROPDOWN_INPUT',
  SET_PACKAGE_LIST_UPGRADE: 'SET_PACKAGE_LIST_UPGRADE',
  SET_LANGUAGE: 'SET_APP_LANGUAGE',

  setToggleSearchCriteria: open => ({
    type: actions.TOOGLE_SEARCH_CRITERIA,
    payload: open,
  }),

  validateSearchCriteria: valid => ({
    type: actions.VALIDATE_SEARCH_CRITERIA,
    payload: valid,
  }),

  updateDateRangeCriteria: (fromDate, toDate) => ({
    type: actions.UPDATE_DATE_RANGE_CRITERIA,
    payload: { from: fromDate, to: toDate },
  }),

  updateColumnCriteria: (name, value) => ({
    type: actions.UPDATE_COLUMN_RANGE_CRITERIA,
    payload: { colName: name, colValue: value },
  }),

  updateWindowSize: (width, height) => ({
    type: actions.UPDATE_WINDOW_SIZE,
    payload: { width: width, height: height },
  }),

  setToggleDropDownInput: open => ({
    type: actions.TOGGLE_DROPDOWN_INPUT,
    payload: open,
  }),

  APP_CHANGE_LANGUAGE: 'APP_CHANGE_LANGUAGE',
  onChangeLanguage: payload => ({
    type: actions.APP_CHANGE_LANGUAGE,
    payload,
  }),
}

export default actions
