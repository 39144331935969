/* eslint-disable no-restricted-globals,eqeqeq */
class ModalHandler {
  windowClicker(id) {
    const modal = document.getElementById(id)
    if (event.target == modal) {
      modal.style.display = 'none'
    }
  }

  closeModal(id) {
    const modal = document.getElementById(id)
    modal.style.display = 'none'
    window.removeEventListener(
      'click',
      event => {
        this.windowClicker(id)
      },
      false
    )
  }

  openModal(id) {
    const modal = document.getElementById(id)
    modal.style.display = 'block'
    window.addEventListener(
      'click',
      event => {
        this.windowClicker(id)
      },
      false
    )
  }
}

export default new ModalHandler()
