const actions = {
  SET_LIST_INVITATIONS: 'SET_LIST_INVITATIONS',
  SET_INVITATION_DETAIL: 'SET_INVITATION_DETAIL_POPUP',

  ON_SEND_INVITATION: 'ON_SEND_INVITATION',
  sendInvitation: (payload, callback) => ({
    type: actions.ON_SEND_INVITATION,
    payload,
    callback,
  }),
  ON_LIST_INVITATIONS: 'ON_LIST_INVITATIONS',
  listInvitations: payload => ({
    type: actions.ON_LIST_INVITATIONS,
    payload,
  }),
  ON_SHOW_INVITATION_DETAIL: 'ON_SHOW_INVITATION_DETAIL',
  onShowInvitationDetail: payload => ({
    type: actions.ON_SHOW_INVITATION_DETAIL,
    payload,
  }),

  ON_IMPORT_INVITATION: 'ON_IMPORT_INVITATION',
  importInvitation: (payload, callback) => ({
    type: actions.ON_IMPORT_INVITATION,
    payload,
    callback,
  }),

  ON_EXPORT_INVITATION: 'ON_EXPORT_INVITATION',
  exportInvitation: (payload, callback) => ({
    type: actions.ON_EXPORT_INVITATION,
    payload,
    callback,
  }),

  ON_REVOKE_INVITATION: 'ON_REVOKE_INVITATION',
  revokeInvitation: payload => ({
    type: actions.ON_REVOKE_INVITATION,
    payload,
  }),

  ON_RESEND_INVITATION: 'ON_RESEND_INVITATION',
  resendInvitation: payload => ({
    type: actions.ON_RESEND_INVITATION,
    payload,
  }),
}

export default actions
