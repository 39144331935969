import actions from './actions'

const initState = {
  readyPage: false,
  readyPageLogin: false,
  readyPageMyEvent: false,
  slider_show: [],
  card: [],
  selectedShareID: 0,
  comment_review: [],
  dataPrcing: null,
  listUpcoming: [],
  listJoined: [],
  listHistory: [],
  listPublic: [],
  listClub: 'undefined',
  inClubHistory: 'undefined',
  inClubUpcoming: 'undefined',
  listMyEvents: [],
  listPackageCreateNewEvents: [],
  listUpcomingAll: [],
  listJoinedAll: [],
  listHistoryAll: [],
  listPublicAll: [],
  listSearchAll: [],
  listMyEventAll: [],
  repliesList: [],
  eventDetail: {
    id: 0,
    cover_img: '',
    logo_img: '',
    title: '',
    time: '',
    text: '',
    photo_gallery_img: [],
    lat: 13,
    lng: 100,
    place: {
      img: '',
      content: '',
    },
  },
  eventDetailOriginal: {
    id: 0,
    title_en: '',
    title_th: '',
    description_en: '',
    description_th: '',
    date: '',
    place: {
      id: 0,
      name_en: '',
      name_th: '',
      address_en: '',
      address_th: '',
      picture: '',
      latitude: 13,
      longitude: 100,
      event_id: 1,
    },
    website: '',
    facebook_link: '',
    username: '',
    status: 'enabled',
    package_id: 1,
    privacy: 'public_view',
  },
  buyTicketDetail: {
    title: '',
    logo_img: '',
  },
  buyTicketDetailOriginal: {
    title_en: '',
    title_th: '',
    description_en: '',
    description_th: '',
    date: '',
  },
  isExpire: false,
  goodsAndDelivery: [],
  buyPackageDetail: {
    id: 1,
    typeBuy: '',
    eventID: '',
    joinType: '',
  },
}

export default function homeReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_HOME_DATA:
      return {
        ...state,
        readyPage: true,
        slider_show: action.payload.bannerData,
        card: action.payload.listEvent,
        dataPrcing: action.payload.listPackages,
        comment_review: action.payload.listTestimonials,
      }
    case actions.SET_HOME_LOGIN_DATA:
      return {
        ...state,
        readyPageLogin: true,
        slider_show: action.payload.bannerData,
        listUpcoming: action.payload.listUpcoming,
        listJoined: action.payload.listJoined,
        listHistory: action.payload.listHistory,
        listPublic: action.payload.listPublic,
        listClub: action.payload.listClub || 'undefined',
        inClubHistory: action.payload.inClubHistory || 'undefined',
        inClubUpcoming: action.payload.inClubUpcoming || 'undefined',
      }
    case actions.SET_MY_EVENTS_DATA:
      return {
        ...state,
        readyPageMyEvent: true,
        readyPageLogin: true,
        listPackageCreateNewEvents: action.payload.listPackageCreateNewEvents,
        listMyEvents: action.payload.listMyEvents,
      }
    case actions.SET_UPCOMING_DATA:
      return {
        ...state,
        readyPageLogin: true,
        listUpcomingAll: action.payload.listUpcomingAll,
      }
    case actions.SET_MY_EVENT_ALL_DATA:
      return {
        ...state,
        readyPageLogin: true,
        listMyEventAll: action.payload.listData,
      }
    case actions.SET_JOIN_DATA:
      return {
        ...state,
        readyPageLogin: true,
        listJoinedAll: action.payload.listJoinedAll,
      }
    case actions.SET_HISTORY_DATA:
      return {
        ...state,
        readyPageLogin: true,
        listHistoryAll: action.payload.listHistoryAll,
      }
    case actions.SET_PUBLIC_DATA:
      return {
        ...state,
        readyPageLogin: true,
        listPublicAll: action.payload.listPublicAll,
      }
    case actions.SET_SEARCH_DATA:
      return {
        ...state,
        readyPageLogin: true,
        listSearchAll: action.payload.listSearchAll,
      }
    case actions.SET_EVENT_DETAIL_DATA:
      return {
        ...state,
        readyPageLogin: true,
        eventDetail: action.payload.eventDetail,
        eventDetailOriginal: action.payload.eventDetailOriginal,
      }
    case actions.SET_EVENT_DETAIL_ORIGINAL_DATA:
      return {
        ...state,
        eventDetailOriginal: action.payload.eventDetailOriginal,
      }
    case actions.DELETE_PHOTO_GALLERY:
      const _temp = { ...state }
      _temp.eventDetail.photo_gallery_img[action.payload.index1][
        action.payload.index2
      ].splice(action.payload.index3, 1)
      return {
        ...state,
        ..._temp,
      }
    case actions.ADD_LOGO_IMAGE:
      const _temp6 = { ...state }
      _temp6.eventDetail.logo_img = action.data
      return {
        ...state,
        ..._temp6,
      }
    case actions.DELETE_IMAGE_COVER:
      const _temp2 = { ...state }
      _temp2.eventDetail.cover_img = ''
      return {
        ...state,
        ..._temp2,
      }
    case actions.DELETE_IMAGE_PLACE_COVER:
      const _temp3 = { ...state }
      _temp3.eventDetailOriginal.place.picture = ''
      return {
        ...state,
        ..._temp3,
      }
    case actions.SET_EVENT_DETAIL_IMAGE_COVER:
      const _temp4 = { ...state }
      _temp4.eventDetail.cover_img = action.data
      return {
        ...state,
        ..._temp4,
      }
    case actions.SET_SHARE_ID:
      return {
        ...state,
        selectedShareID: action.id,
      }
    case actions.ADD_PHOTO_GALLERY:
      let _temp5 = { ...state }
      if (_temp5.eventDetail.photo_gallery_img.length === 0) {
        _temp5.eventDetail.photo_gallery_img.push([[{ img_url: action.data }]])
      } else {
        const lastPageData =
          _temp5.eventDetail.photo_gallery_img[
          _temp5.eventDetail.photo_gallery_img.length - 1
          ]
        const lastRow = lastPageData[lastPageData.length - 1]
        if (lastRow.length >= 5) {
          if (lastPageData.length >= 2) {
            _temp5.eventDetail.photo_gallery_img.push([
              [{ img_url: action.data }],
            ])
          } else {
            lastPageData.push([{ img_url: action.data }])
          }
        } else {
          lastRow.push({ img_url: action.data })
        }
      }
      return {
        ...state,
        ..._temp5,
      }
    case actions.RESET_DETAIL:
      return {
        ...state,
        buyTicketDetail: state.eventDetail,
        buyTicketDetailOriginal: state.eventDetailOriginal,
        eventDetailOriginal: initState.eventDetailOriginal,
        eventDetail: initState.eventDetail,
      }
    case actions.SET_NEW_REPLY_MESSAGE:
      return {
        ...state,
        eventDetailOriginal: {
          ...state.eventDetailOriginal,
          invitationDetail: {
            ...state.eventDetailOriginal.invitationDetail,
            reply_message: action.reply_message,
          },
        },
        eventDetail: {
          ...state.eventDetail,
          invitationDetail: {
            ...state.eventDetail.invitationDetail,
            reply_message: action.reply_message,
          },
        },
      }
    case actions.SET_ASSIGN_REPLY_MESSAGE_MORE:
      return {
        ...state,
        repliesList: [...state.repliesList, action.payload],
      }
    case actions.RESET_ALL:
      return {
        ...state,
        ...initState,
      }
    case actions.SET_EXPIRE:
      return {
        ...state,
        isExpire: action.payload,
      }
    case actions.SET_SEARCH_LOAD:
      return {
        ...state,
        ...initState,
        readyPageLogin: true,
      }
    case actions.SET_GOODS_DELIVERY_BY_EVENT:
      return {
        ...state,
        goodsAndDelivery: action.payload
      }
    case actions.SET_BUY_PACKAGE_DETAIL:
      return {
        ...state,
        buyPackageDetail: action.payload
      }
    default:
      return state
  }
}
