import actions from './actions'

const initState = {
  user_of: '',
  user_info: {
    firstname: '',
    lastname: '',
    student_id: '',
  },
  joined: false,
}

export default function clubRegisterReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_PROFILE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
