import { all } from 'redux-saga/effects'
import appSaga from './app/saga'
import authSaga from './auth/saga'
import homeSaga from './home/saga'
import paymentSaga from './payment/saga'
import invitationSaga from './invitation/saga'
import transactionSaga from './transaction/saga'

export default function* rootSaga(getState) {
  yield all([
    appSaga(),
    authSaga(),
    homeSaga(),
    paymentSaga(),
    invitationSaga(),
    transactionSaga(),
  ])
}
