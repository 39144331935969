import actions from './actions'

const initState = {
  totalSummary: {
    event: {
      logo: '',
      title_en: '',
      title_th: '',
    },
    package: {
      name_en: '',
      name_th: '',
      currency: 'thb',
    },
    invitation_count: 0,
    joined_count: 0,
    max_invitations: 0,
    total_amount: '0',
  },
  transactionList: [],
  transactionDetail: {
    id: 0,
    transaction_type: '',
    name: '',
    email: '',
    payment_method: '',
    amount: '',
    created_at: '',
    status: '',
  },
}

export default function homeReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_LIST_TRANSACTION:
      return {
        ...state,
        transactionList: action.payload.transactionList,
        totalSummary: action.payload.totalSummary,
      }
    case actions.SET_TRANSACTION_DETAIL:
      return {
        ...state,
        transactionDetail: action.payload,
      }
    default:
      return state
  }
}
