import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/th'

import { getLanguage } from '../locale'

const lang = getLanguage().langLower
const UCT_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ'

moment.locale(lang === 'EN' ? 'en-gb' : lang)

/**
 * @return {string}
 */
export function ISODateString(d) {
  function pad(n) {
    return n < 10 ? '0' + n : n
  }

  return (
    d.getUTCFullYear() +
    '-' +
    pad(d.getUTCMonth() + 1) +
    '-' +
    pad(d.getUTCDate()) +
    'T' +
    pad(d.getUTCHours()) +
    ':' +
    pad(d.getUTCMinutes()) +
    ':' +
    pad(d.getUTCSeconds()) +
    'Z'
  )
}

/**
 * @return {string}
 */
export function ConvertISODate1(a) {
  return moment(a, UCT_FORMAT)
    .local()
    .format('DD MMM YYYY - HH:mm')
}

/**
 * @return {string} YYYY-MM-DD
 */
export function ConvertISODate2(a) {
  return moment(a, UCT_FORMAT)
    .local()
    .format('YYYY-MM-DD')
}

/**
 * @return {string} YYYY-MM-DD
 */
export function ConvertISODateTime2(a) {
  return moment(a, UCT_FORMAT)
    .local()
    .format('YYYY-MM-DD HH:mm')
}

export function getShowDate(lang = 'en', date) {
  let d = moment(date, UCT_FORMAT)
    .local()
    .locale(lang)
  return d.format('DD MMM YYYY - HH:mm')
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

/**
 * @return {string}
 */
export function GetCardType(number) {
  // visa
  let re = new RegExp('^4')
  if (number.match(re) != null) return 'Visa'

  // Mastercard
  // Updated for Mastercard 2017 BINs expansion
  if (
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
      number
    )
  )
    return 'Mastercard'

  // AMEX
  re = new RegExp('^3[47]')
  if (number.match(re) != null) return 'AMEX'

  // Discover
  re = new RegExp(
    '^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)'
  )
  if (number.match(re) != null) return 'Discover'

  // Diners
  re = new RegExp('^36')
  if (number.match(re) != null) return 'Diners'

  // Diners - Carte Blanche
  re = new RegExp('^30[0-5]')
  if (number.match(re) != null) return 'Diners - Carte Blanche'

  // JCB
  re = new RegExp('^35(2[89]|[3-8][0-9])')
  if (number.match(re) != null) return 'JCB'

  // Visa Electron
  re = new RegExp('^(4026|417500|4508|4844|491(3|7))')
  if (number.match(re) != null) return 'Visa Electron'

  return 'Visa'
}

export function toUpperCaseFirst(string = '') {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function convertTransactionOrderType(order_type, locale) {
  if (order_type === 'buy_ticket') {
    return locale.TRANSACTION_ORDER_TYPE_JOIN
  } else if (order_type === 'buy_package') {
    return locale.TRANSACTION_ORDER_TYPE_CREATE
  } else if (order_type === 'upgrade_package') {
    return locale.TRANSACTION_ORDER_TYPE_UPGRADE
  } else {
    return order_type
  }
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export function convertDateTimeZ(date) {
  if (date) {
    const a = date.split('T')
    const b = a[1].split('.')
    const c = b[0].split(':00Z')
    return a[0] + ' ' + c[0]
  } else {
    return '1990-01-01 00:00'
  }
}

export function dateDiff(max, min) {
  const a = moment(max)
  const b = min ? moment(min) : moment(moment().format('YYYY-MM-DD HH:mm'))
  return a.diff(b)
}
