import axios from 'axios'
import { getShowDate } from './string'
import {
  getAccessToken,
  getFacebookIDToken,
  getFacebookToken,
  getIdToken,
} from './auth-utils.js'
import { getLanguage } from '../locale'
import langEN from '../locale/en'
import langTH from '../locale/th'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const END_POINT = process.env.GATSBY_API_ENDPOINT
const CLUB_KMITL = process.env.GATSBY_CLUB_KMITL

function checkToken() {
  const accessToken = getAccessToken().get('accessToken') || getFacebookToken()
  const idToken = getIdToken().get('idToken') || getFacebookIDToken()
  return {
    accessToken,
    idToken,
  }
}

function httpRequest(method, url, data, responseType, addHeader) {
  let addOnHeader = {}

  const token = checkToken()
  // console.log('token:', token)

  if (token.accessToken) {
    addOnHeader = {
      ...addOnHeader,
      Authorization: token.accessToken,
    }
  }

  if (token.idToken) {
    addOnHeader = {
      ...addOnHeader,
      'x-id-token': token.idToken,
    }
  }

  if (addHeader) {
    addOnHeader = {
      ...addOnHeader,
      ...addHeader,
      // 'X-Profile-ID': cookies.get('_id'),
    }
  } else {
    if (cookies.get('_id')) {
      addOnHeader = {
        ...addOnHeader,
        'X-Profile-ID': cookies.get('_id'),
      }
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...addOnHeader,
  }
  return axios({
    method,
    url,
    headers,
    data,
    responseType,
  })
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}

const instanceForm = axios.create({
  baseURL: process.env.GATSBY_API_AUTH_ENDPOINT,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
})

function oauthRequest(code) {
  let formData = new FormData()
  formData.append('client_id', process.env.GATSBY_AWS_USER_POOL_WEB_CLIENT_ID)
  formData.append('code', code)
  formData.append('grant_type', 'authorization_code')
  formData.append('redirect_uri', process.env.GATSBY_URL_REDIRECT)

  return instanceForm
    .post('/oauth2/token', formData)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}

export const delay = ms =>
  new Promise(resolve => setTimeout(() => resolve(true), ms))

// https://gitlab.com/invitree/invitree-api/wikis/API-Categories/Banners-API
// Get Banners
export async function getBanner(lang) {
  try {
    const data = await httpRequest(
      'GET',
      `${END_POINT}/banners?limit=10&offset=0`,
      null
    )
    if (!data.data.banners) {
      data.data.banners = []
      // data.data.banners = [{
      //   id: 1,
      //   landscape_picture: 'https://sv1.picz.in.th/images/2019/01/19/9FYdtv.png',
      // }, {
      //   id: 2,
      //   landscape_picture: 'https://sv1.picz.in.th/images/2019/01/19/9FYdtv.png',
      // }, {
      //   id: 3,
      //   landscape_picture: 'https://sv1.picz.in.th/images/2019/01/19/9FYdtv.png',
      // }]
    }
    const convertData = data => {
      return data.map(item => {
        const img =
          lang === 'EN' ? item.landscape_picture_en : item.landscape_picture_th
        item.img_url = img + '?sz=m'
        if (item.img_url === 'undefined?sz=m') {
          item.img_url = process.env.GATSBY_PLACEHOLDERS_IMAGE_BANNER + '?sz=m'
        }
        return item
      })
    }
    data.data.banners = convertData(data.data.banners) || []
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// https://gitlab.com/invitree/invitree-api/wikis/API-Categories/Events-API
// Get Event Detail
export async function getEventDetail(id, invitation_id, invitation_pin) {
  const lang = getLanguage().lang
  try {
    let queryString = `${id}`
    if (invitation_pin && invitation_id) {
      queryString += `?invitation_id=${invitation_id}&invitation_pin=${invitation_pin}`
    }

    const response = await httpRequest(
      'GET',
      `${END_POINT}/events/${queryString}`,
      null
    )
    const data = response.data

    // const data = {
    //   data: {
    //     title_en: 'Emily & Mateus - Wedding Celemony',
    //     title_th: 'Emily & Mateus - Wedding Celemony',
    //     description_en: 'You are cordially invited to celebrate the wedding of Emily and Mateus. This is the part where the wedding party walks down the aisle and takes their places for the ceremony. You can each make your way to the altar separately, symbolizing the fact that you’re coming from different backgrounds. In a Christian procession, the bride is escorted by her father, while the groom waits for her at the altar. In a Jewish procession, the groom’s parents escort him down the aisle, and then the bride’s parents escort her down the aisle. This may also include a reminder of your duties and roles in marriage. ',
    //     description_th: 'You are cordially invited to celebrate the wedding of Emily and Mateus. This is the part where the wedding party walks down the aisle and takes their places for the ceremony. You can each make your way to the altar separately, symbolizing the fact that you’re coming from different backgrounds. In a Christian procession, the bride is escorted by her father, while the groom waits for her at the altar. In a Jewish procession, the groom’s parents escort him down the aisle, and then the bride’s parents escort her down the aisle. This may also include a reminder of your duties and roles in marriage. ',
    //     date: '2011-10-05T14:48:00.000Z',
    //     place: {
    //       id: 1,
    //       name_en: 'Hi-So @ So Sofitel Bangkok Hotel',
    //       name_th: 'ไฮโซ @ โรงแรมโซฟิเทลกรุงเทพฯ',
    //       address_en: 'Hi-So @ So Sofitel Bangkok Hotel\n             38th Floor So Sofitel Bangkok Hotel Sathon Rd, Bang Rak,\n            Bang Rak, Krung Thep Maha Nakhon 10500',
    //       address_th: 'ชั้น 38 โรงแรมโซฟิเทลกรุงเทพฯ ถนนสาทร เขตบางรัก กรุงเทพมหานคร 10500',
    //       picture: 'https://sv1.picz.in.th/images/2019/01/22/T00uqN.png',
    //       latitude: 13.6731972,
    //       longitude: 100.6051033,
    //       event_id: 1,
    //     },
    //     website: 'https://www.kmitlreunion2019.com',
    //     facebook: 'https://www.facebook.com/pages/kmitlreunion2019',
    //     username: 'username',
    //     status: 'enabled',
    //     package_id: 1,
    //     privacy: 'public_view',
    //   },
    // }
    let filter = '?sz=s&updated_at' + data.updated_at

    data.place.picture = data.place.picture ? data.place.picture + filter : ''
    data.website = data.website || ''
    data.facebook_link = data.facebook_link || ''

    const convertGalleryImages = () => {
      const sizePage = 10
      let result = []
      if (data.pictures) {
        if (data.pictures.length > 0) {
          const loop_page = Math.ceil(data.pictures.length / sizePage)
          let r = []
          let i1 = 0,
            i2 = 0
          data.pictures.forEach(function (v) {
            if (r.length <= 0) {
              r.push([[{ img_url: v }]])
            } else {
              if (r[i1][i2].length < 5) {
                r[i1][i2].push({ img_url: v })
              } else {
                i2++
                r[i1].push([{ img_url: v }])
              }
            }
          })
          let ie = 0
          for (let i = 0; i < loop_page; i++) {
            const x = r[0][ie] || []
            const x2 = r[0][ie + 1] || []
            result.push([x, x2])
            ie = ie + 2
          }
        }
      }
      return result
    }

    //TODO: DEMO TEST

    // function convertGalleryImage(img){
    //   let pageRow = [];
    //   let pageRowIndex = -1;
    //   let rowIndex = -1;
    //   img.forEach(function(v,i) {
    //     if(i===0){
    //       pageRow = [
    //         [
    //           [v]
    //         ]
    //       ];
    //       pageRowIndex = pageRow.length-1;
    //       rowIndex =
    //     }else{
    //       pageRow[pageRowIndex].push([
    //         [v]
    //       ]);
    //     }
    //   });
    // }

    let title
    let description
    let address
    if (lang === 'TH') {
      title = data.title_th.length > 0 ? data.title_th : data.title_en
      description =
        data.description_th.length > 0
          ? data.description_th
          : data.description_en
      address =
        data.place.address_th.length > 0
          ? data.place.address_th
          : data.place.address_en
    } else {
      title = data.title_en.length > 0 ? data.title_en : data.title_th
      description =
        data.description_en.length > 0
          ? data.description_en
          : data.description_th
      address =
        data.place.address_en.length > 0
          ? data.place.address_en
          : data.place.address_th
    }

    const result = {
      cover_img: data.banner
        ? data.banner + filter
        : process.env.GATSBY_PLACEHOLDERS_IMAGE_EVENT_BANNER + filter,
      logo_img: data.logo
        ? data.logo + filter
        : process.env.GATSBY_PLACEHOLDERS_IMAGE_EVENT_LOGO + filter,
      title: title,
      time: getShowDate(lang, data.date),
      text: description,
      photo_gallery_img: convertGalleryImages(),
      // photo_gallery_img: [
      //   [
      //     [
      //       { img_url: 'https://sv1.picz.in.th/images/2019/01/14/9p2eMa.png' },
      //       { img_url: 'https://sv1.picz.in.th/images/2019/01/14/9p2eMa.png' },
      //       { img_url: 'https://sv1.picz.in.th/images/2019/01/14/9p2eMa.png' },
      //       { img_url: 'https://sv1.picz.in.th/images/2019/01/14/9p2eMa.png' },
      //       { img_url: 'https://sv1.picz.in.th/images/2019/01/14/9p2eMa.png' },
      //     ],
      //     [
      //       { img_url: 'https://sv1.picz.in.th/images/2019/01/14/9p2eMa.png' },
      //       { img_url: 'https://sv1.picz.in.th/images/2019/01/14/9p2eMa.png' },
      //       { img_url: 'https://sv1.picz.in.th/images/2019/01/14/9p2eMa.png' },
      //       { img_url: 'https://sv1.picz.in.th/images/2019/01/14/9p2eMa.png' },
      //       { img_url: 'https://sv1.picz.in.th/images/2019/01/14/9p2eMa.png' },
      //     ],
      //   ],
      //   [
      //     [
      //       { img_url: 'https://sv1.picz.in.th/images/2019/01/14/9p2eMa.png' },
      //       { img_url: 'https://sv1.picz.in.th/images/2019/01/14/9p2eMa.png' },
      //       { img_url: 'https://sv1.picz.in.th/images/2019/01/14/9p2eMa.png' },
      //       { img_url: 'https://sv1.picz.in.th/images/2019/01/14/9p2eMa.png' },
      //       { img_url: 'https://sv1.picz.in.th/images/2019/01/14/9p2eMa.png' },
      //     ],
      //   ],
      // ],
      lat: data.place.latitude,
      lng: data.place.longitude,
      place: {
        img:
          data.place.picture ||
          process.env.GATSBY_PLACEHOLDERS_IMAGE_EVENT_PLACE + filter,
        content: address,
      },
      website: '',
      facebook_link: '',
    }
    return {
      code: 'OK',
      text: null,
      data: {
        original: data,
        result,
      },
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// Get goods detail by event_id
export async function getGoodsByEventID(id) {
  let queryString = `?event_id=${id}&limit=300`
  try {
    const response = await httpRequest(
      'GET',
      `${END_POINT}/goods${queryString}`,
      null
    )
    const data = response.data
    return {
      code: 'OK',
      text: null,
      data: data
    }
  } catch (error) {
    return {
      code: 'OK',
      text: error,
      data: null
    }
  }
}

// Get delivery detail by event_id
export async function getDeliveryByEventID(id) {
  let queryString = `?event_id=${id}&limit=300`
  try {
    const response = await httpRequest(
      'GET',
      `${END_POINT}/delivery${queryString}`,
      null
    )
    const data = response.data
    return {
      code: 'OK',
      text: null,
      data: data
    }
  } catch (error) {
    return {
      code: 'OK',
      text: error,
      data: null
    }
  }
}

// Get Event Detail Check Invitation Status
export async function getEventDetailInvitation(id) {
  const token = checkToken()

  if (!token.accessToken && !token.idToken) {
    return {
      code: 'FAIL',
      text: 'NO_LOGIN',
      data: null,
    }
  } else {
    try {
      const data = await httpRequest(
        'GET',
        `${END_POINT}/events/${id}/invitation`
      )
      return {
        code: 'OK',
        text: null,
        data: data.data,
      }
    } catch (e) {
      return {
        code: 'FAIL',
        text: e,
        data: null,
      }
    }
  }
}

// List Events
export async function getListEvents(category, filter = '') {
  const lang = getLanguage().lang
  try {
    const data = await httpRequest(
      'GET',
      `${END_POINT}/events?limit=30&offset=0&category=${category}${filter}`,
      null
    )
    // console.log('data.data.events:', data.data.events)

    if (!data.data.events) {
      data.data.events = []
      // data.data.events = [{
      //   id: 1,
      //   img_url: 'https://sv1.picz.in.th/images/2019/01/19/9F7YAE.png',
      //   title_en: 'Jane & Mark Wedding Celemony',
      //   description_en: 'As we unite in marriage on Saturday, 8th of January two thousand nineteen seven o’clock at St Mary’s Cathedral. Dinner and dancing to follow.',
      //   date: '8 Jan 2019 - 7:00 PM',
      // }, {
      //   id: 2,
      //   img_url: 'https://sv1.picz.in.th/images/2019/01/19/9F7YAE.png',
      //   title_en: 'Jane & Mark Wedding Celemony',
      //   description_en: 'As we unite in marriage on Saturday, 8th of January two thousand nineteen seven o’clock at St Mary’s Cathedral. Dinner and dancing to follow.',
      //   date: '8 Jan 2019 - 7:00 PM',
      // }, {
      //   id: 3,
      //   img_url: 'https://sv1.picz.in.th/images/2019/01/19/9F7YAE.png',
      //   title_en: 'Jane & Mark Wedding Celemony',
      //   description_en: 'As we unite in marriage on Saturday, 8th of January two thousand nineteen seven o’clock at St Mary’s Cathedral. Dinner and dancing to follow.',
      //   date: '8 Jan 2019 - 7:00 PM',
      // }, {
      //   id: 4,
      //   img_url: 'https://sv1.picz.in.th/images/2019/01/19/9F7YAE.png',
      //   title_en: 'Jane & Mark Wedding Celemony',
      //   description_en: 'As we unite in marriage on Saturday, 8th of January two thousand nineteen seven o’clock at St Mary’s Cathedral. Dinner and dancing to follow.',
      //   date: '8 Jan 2019 - 7:00 PM',
      // }]
    }
    const convertData = data => {
      const gridColSize = 3
      const _data = []
      if (data.length > 0) {
        // if (category === 'owned') {
        //   data.sort((a, b) => b.id - a.id)
        // }
        const max_loop = Math.ceil(data.length / gridColSize)
        let lastIndex = -1
        for (let i = 1; i <= max_loop; i++) {
          const _data2 = []
          let maxStop = gridColSize
          // eslint-disable-next-line
          data.forEach((v, i2) => {
            if (i2 > lastIndex && maxStop > 0) {
              let title
              let description
              if (lang === 'TH') {
                title = v.title_th.length > 0 ? v.title_th : v.title_en
                description =
                  v.description_th.length > 0
                    ? v.description_th
                    : v.description_en
              } else {
                title = v.title_en.length > 0 ? v.title_en : v.title_th
                description =
                  v.description_en.length > 0
                    ? v.description_en
                    : v.description_th
              }

              _data2.push({
                id: v.id,
                logo_img: v.logo
                  ? v.logo + '?sz=s'
                  : process.env.GATSBY_PLACEHOLDERS_IMAGE_EVENT_LOGO + '?sz=s',
                img_url: v.banner
                  ? v.banner + '?sz=s'
                  : process.env.GATSBY_PLACEHOLDERS_IMAGE_EVENT_BANNER +
                  '?sz=s',
                title: title,
                time: v.date,
                text: description,
                package_id: v.package_id,
              })
              lastIndex = i2
              maxStop--
            }
          })
          _data.push(_data2)
        }
      }
      return _data
    }
    data.data.events = convertData(data.data.events)
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: { events: [] },
    }
  }
}

// https://gitlab.com/invitree/invitree-api/wikis/API-Categories/Packages-API
// Get Package Detail
export async function getPackageDetail(id) {
  try {
    const data = await httpRequest('GET', `${END_POINT}/packages/${id}`, null)
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// List Events
export async function getListPackages() {
  const lang = getLanguage().lang
  try {
    const data = await httpRequest('GET', `${END_POINT}/packages`, null)
    if (!data.data.packages) {
      data.data.packages = []
      // data.data.packages = [{
      //   id: 1,
      //   name_en: 'Free',
      //   price: 0,
      //   currency: 'THB',
      //   max_invitations: 20,
      //   allow_payment: false,
      // }, {
      //   id: 2,
      //   name_en: 'Standard',
      //   price: 500,
      //   currency: 'THB',
      //   max_invitations: 100,
      //   allow_payment: true,
      // }, {
      //   id: 3,
      //   name_en: 'Premium',
      //   price: 1000,
      //   currency: 'THB',
      //   max_invitations: 500,
      //   allow_payment: true,
      // }]
    }
    const data_head = []
    const priceForThePlan = []
    const numberOfInviteesPerEvent = []
    const availableOnWebsite = []
    const googleCalendarMap = []
    const qrCodeForRegis = []
    const unlimitedPhotoStorage = []
    const pushEventNotification = []
    const greetingMessage = []
    const summaryOfEventParticipants = []
    const onlinePayment = []

    if (data.data.packages.length > 0) {
      data_head.push('')
      priceForThePlan.push(
        lang === 'TH' ? langTH.PRICE_FOR_THE_PLAN : langEN.PRICE_FOR_THE_PLAN
      )
      numberOfInviteesPerEvent.push(
        lang === 'TH'
          ? langTH.NUMBER_OF_INVITEES_PER_EVENT
          : langEN.NUMBER_OF_INVITEES_PER_EVENT
      )
      availableOnWebsite.push(
        lang === 'TH'
          ? langTH.AVAILABLE_ON_WEBSITE
          : langEN.AVAILABLE_ON_WEBSITE
      )
      googleCalendarMap.push(
        lang === 'TH' ? langTH.GOOGLE_CALENDAR_MAP : langEN.GOOGLE_CALENDAR_MAP
      )
      qrCodeForRegis.push(
        lang === 'TH' ? langTH.QR_CODE_FOR_REGIS : langEN.QR_CODE_FOR_REGIS
      )
      unlimitedPhotoStorage.push(
        lang === 'TH'
          ? langTH.UNLIMITED_PHOTO_STORAGE
          : langEN.UNLIMITED_PHOTO_STORAGE
      )
      pushEventNotification.push(
        lang === 'TH'
          ? langTH.PUSH_EVENT_NOTIFICATION
          : langEN.PUSH_EVENT_NOTIFICATION
      )
      greetingMessage.push(
        lang === 'TH' ? langTH.GREETING_MESSAGE : langEN.GREETING_MESSAGE
      )
      summaryOfEventParticipants.push(
        lang === 'TH'
          ? langTH.SUMMARY_OF_EVENT_PARTICIPANTS
          : langEN.SUMMARY_OF_EVENT_PARTICIPANTS
      )
      onlinePayment.push(
        lang === 'TH' ? langTH.ONLINE_PAYMENT : langEN.ONLINE_PAYMENT
      )
      data.data.packages.forEach(v => {
        data_head.push(lang === 'TH' ? v.name_th : v.name_en)
        priceForThePlan.push(v.price + ' ' + v.currency)
        numberOfInviteesPerEvent.push(v.max_invitations)
        availableOnWebsite.push(true)
        googleCalendarMap.push(true)
        qrCodeForRegis.push(true)
        unlimitedPhotoStorage.push(true)
        pushEventNotification.push(true)
        greetingMessage.push(true)
        summaryOfEventParticipants.push(true)
        onlinePayment.push(v.allow_payment)
      })
      // data.data.packages = {
      //   data_head: ['', 'FREE', 'STANDARD', 'PREMUM'],
      //   data_list: [
      //     {
      //       type: 'integer',
      //       datas: ['Price for the plan', '0', '500 THB', '1,000 THB'],
      //     },
      //     {
      //       type: 'integer',
      //       datas: ['Number of invitees per event', '20', '100', '500'],
      //     },
      //     {
      //       type: 'bool',
      //       datas: ['Facebook Event and Notification Plug-in', true, true, true],
      //     },
      //     {
      //       type: 'bool',
      //       datas: ['Google Calendar and Map Plug-in', false, true, true],
      //     },
      //     {
      //       type: 'bool',
      //       datas: ['In-app Online Payment', false, true, true],
      //     },
      //   ],
      // }
    }

    data.data.packages = {
      data_head,
      data_list: [
        {
          type: 'integer',
          datas: priceForThePlan,
        },
        {
          type: 'integer',
          datas: numberOfInviteesPerEvent,
        },
        {
          type: 'bool',
          datas: availableOnWebsite,
        },
        {
          type: 'bool',
          datas: googleCalendarMap,
        },
        {
          type: 'bool',
          datas: qrCodeForRegis,
        },
        {
          type: 'bool',
          datas: unlimitedPhotoStorage,
        },
        {
          type: 'bool',
          datas: pushEventNotification,
        },
        {
          type: 'bool',
          datas: greetingMessage,
        },
        {
          type: 'bool',
          datas: summaryOfEventParticipants,
        },
        {
          type: 'bool',
          datas: onlinePayment,
        },
      ],
    }
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

function fnConvertPackageList(data) {
  const lang = getLanguage().lang
  const result = []
  let lastIndex = 0
  data.forEach((v, i) => {
    if (!result[lastIndex]) {
      result[lastIndex] = []
    }
    const name = lang === 'TH' ? v.name_th : v.name_en
    let bgClassType = name === 'Premium' ? 3 : name === 'Standard' ? 2 : 1
    let allow_payment = v.allow_payment ? 'Online Payment' : null
    result[lastIndex].push({
      type: name,
      package_id: v.id,
      price: v.price,
      currency: v.currency.toUpperCase(),
      perAt: 'per event',
      desc: [`${v.max_invitations} invitees per event`, allow_payment],
      bgClassType,
      isHot: false,
      ...v,
    })

    if ((i + 1) % 3 === 0) {
      lastIndex = lastIndex + 1
    }
  })
  return result
}

export async function getListPackagesForCreate() {
  try {
    const data = await httpRequest('GET', `${END_POINT}/packages`, null)
    if (!data.data.packages) {
      data.data.packages = []
      // TODO: DEMO
      // data.data.packages = [
      //   [
      //     {
      //       type: 'Free',
      //       package_id: 1,
      //       price: '0',
      //       currency: 'THB',
      //       perAt: 'per event',
      //       desc: ['20 invitees per event'],
      //       bgClassType: 1,
      //       isHot: false,
      //     },
      //     {
      //       type: 'Standard',
      //       package_id: 2,
      //       price: '500',
      //       currency: 'THB',
      //       perAt: 'per event',
      //       desc: ['100 invitees per event', 'Facebook Notification', 'Online Payment'],
      //       bgClassType: 2,
      //       isHot: true,
      //     },
      //     {
      //       type: 'Premium',
      //       package_id: 3,
      //       price: '1,000',
      //       currency: 'THB',
      //       perAt: 'per event',
      //       desc: ['500 invitees per event', 'Facebook Notification', 'Online Payment'],
      //       bgClassType: 3,
      //       isHot: false,
      //     },
      //   ],
      // ]
    } else {
      // const result = []
      // data.data.packages.forEach((v) => {
      //   let bgClassType = v.name_en === 'Premium' ? 3 : v.name_en === 'Standard' ? 2 : 1
      //   let allow_payment = v.allow_payment ? 'Online Payment' : null
      //   result.push({
      //     type: v.name_en,
      //     package_id: v.id,
      //     price: v.price,
      //     currency: v.currency.toUpperCase(),
      //     perAt: 'per event',
      //     desc: [`${v.max_invitations} invitees per event`, allow_payment],
      //     bgClassType,
      //     isHot: false,
      //   })
      // })
      // const _temp = [...data.data.packages]
      // data.data.packages = [result]
      data.data.packages = fnConvertPackageList(data.data.packages)
    }
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// https://gitlab.com/invitree/invitree-api/wikis/API-Categories/Profile-API
// Get Profile
export async function getProfile() {
  try {
    const data = await httpRequest('GET', `${END_POINT}/profile`, null)
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// Sync Profile with AWS Cognito
export async function syncProfileCognito() {
  try {
    const data = await httpRequest('PUT', `${END_POINT}/profile`, null)
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// Update Profile
export async function updateProfile(
  payload = {
    phone_number: '',
    name: '',
    picture: '',
  }
) {
  try {
    const data = await httpRequest('POST', `${END_POINT}/profile`, payload)
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    let messageError = null
    // if (e.search('InvalidParameterException') > -1) {
    //   messageError = 'Invalid phone number format.'
    // }

    return {
      code: 'FAIL',
      text: messageError ? messageError : e,
      data: null,
    }
  }
}

export async function getTokenOAuth(code) {
  try {
    const data = await oauthRequest(code)
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

export function oAuthGetToken(code) {
  const settings = {
    async: true,
    crossDomain: true,
    url: process.env.GATSBY_AUTH_TOKEN,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: {
      client_id: process.env.GATSBY_AWS_USER_POOL_WEB_CLIENT_ID,
      code: code,
      grant_type: 'authorization_code',
      redirect_uri: process.env.GATSBY_URL_REDIRECT,
    },
  }

  return new Promise(function (resolve) {
    // eslint-disable-next-line
    $.ajax(settings).done(function (response) {
      resolve(response)
    })
  })
}

// Create Order
export async function createOrderAPI(payload) {
  try {
    const data = await httpRequest('POST', `${END_POINT}/orders`, payload)
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e.error,
      data: null,
    }
  }
}

// Get Order Detail
export async function getOrderAPI(id) {
  try {
    const data = await httpRequest('GET', `${END_POINT}/orders/${id}`)
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// Charge Order (Payment)
export async function orderChargeAPI(orderID, payload) {
  try {
    const data = await httpRequest(
      'POST',
      `${END_POINT}/orders/${orderID}/charge`,
      payload
    )
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e.error,
      data: null,
    }
  }
}

// Update Event Detail
export async function updateEventDetailAPI(id, payload) {
  try {
    const data = await httpRequest('POST', `${END_POINT}/events/${id}`, payload)
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e.error,
      data: null,
    }
  }
}

// Upload Event Place Image
export async function uploadEventPlaceImageAPI(id, payload) {
  try {
    const data = await httpRequest(
      'POST',
      `${END_POINT}/events/${id}/place_img`,
      payload
    )
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// Upload Event Banner
export async function uploadEventBannerImageAPI(id, payload) {
  try {
    const data = await httpRequest(
      'POST',
      `${END_POINT}/events/${id}/banner`,
      payload
    )
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// Upload Event Logo
export async function uploadEventLogoAPI(id, payload) {
  try {
    const data = await httpRequest(
      'POST',
      `${END_POINT}/events/${id}/logo`,
      payload
    )
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// Upload Profile
export async function uploadProfileImageAPI(payload) {
  try {
    const data = await httpRequest(
      'POST',
      `${END_POINT}/profile/picture`,
      payload
    )
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// Add Event Image
export async function uploadEventImageAPI(id, payload) {
  try {
    const data = await httpRequest(
      'POST',
      `${END_POINT}/events/${id}/images`,
      payload
    )
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// Delete Event Banner
export async function deleteEventBannerImageAPI(id) {
  try {
    const data = await httpRequest('DELETE', `${END_POINT}/events/${id}/banner`)
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// Delete Event Logo
export async function deleteEventLogoAPI(id) {
  try {
    const data = await httpRequest('DELETE', `${END_POINT}/events/${id}/logo`)
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// Delete Profile Image
export async function deleteProfileImageAPI() {
  try {
    const data = await httpRequest('DELETE', `${END_POINT}/profile/picture`)
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// Delete Event Image
export async function deleteEventImageAPI(id, fname) {
  const a = fname.split('/')
  try {
    const data = await httpRequest(
      'DELETE',
      `${END_POINT}/events/${id}/images?fname=${a[a.length - 1]}`
    )
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// Delete Event Place Image
export async function deleteEventPlaceImageAPI(id) {
  try {
    const data = await httpRequest(
      'DELETE',
      `${END_POINT}/events/${id}/place_img`
    )
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// Send Invitation
export async function sendInvitationAPI(payload) {
  try {
    const data = await httpRequest('POST', `${END_POINT}/invitations`, payload)
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// resend Invitation
export async function resendInvitationAPI(id) {
  try {
    const data = await httpRequest(
      'POST',
      `${END_POINT}/invitations/${id}/resend`
    )
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// revoke Invitation
export async function revokeInvitationAPI(id) {
  try {
    const data = await httpRequest(
      'POST',
      `${END_POINT}/invitations/${id}/revoke`
    )
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

export async function acceptInvitation(invitation_id, invitation_pin) {
  try {
    const response = await httpRequest(
      'POST',
      `${END_POINT}/invitations/${invitation_id}/accept`,
      { pin: invitation_pin }
    )
    return {
      code: 'OK',
      text: null,
      data: response.data,
    }
  } catch (error) {
    return {
      code: 'FAIL',
      text: error,
      data: null,
    }
  }
}

// Get Invitation Detail
export async function getInvitationDetailAPI(id) {
  try {
    const data = await httpRequest('GET', `${END_POINT}/invitations/${id}`)
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// Get Event Total Sum
export async function getEventTotalSum(id) {
  try {
    const data = await httpRequest('GET', `${END_POINT}/events/${id}/total`)
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// Get Package List for Upgrade
export async function getPackageListForUpgrade(event_id_to_be_upgraded) {
  try {
    const data = await httpRequest(
      'GET',
      `${END_POINT}/packages?upgrade_event=${event_id_to_be_upgraded}`
    )
    if (!data.data.packages) {
      data.data.packages = []
    } else {
      data.data.packages = fnConvertPackageList(data.data.packages)
    }
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// Get Profile Transactions
export async function getProfileTransactions(search) {
  let filter = ''
  if (search.keyword !== '') {
    if (search.keywordType === 'date') {
      filter = `?${search.keywordType}=${search.keyword.form}&from=${search.keyword.form
        }&to=${search.keyword.to}`
    } else {
      filter = `?${search.keywordType}=${search.keyword}`
    }
  }
  try {
    const data = await httpRequest(
      'GET',
      `${END_POINT}/profile/transactions${filter}`
    )
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// List Invitations
export async function listInvitationsAPI(id, search) {
  let filter = ''
  if (search && search.keyword !== '') {
    if (search.keywordType === 'created_at') {
      filter = `&${search.keywordType}=${search.keyword.form}&from=${search.keyword.form
        }&to=${search.keyword.to}`
    } else {
      filter = `&${search.keywordType}=${search.keyword}`
    }
  }
  try {
    const data = await httpRequest(
      'GET',
      `${END_POINT}/invitations?event_id=${id}${filter}`
    )

    data.data.invitations =
      data.data.invitations ||
      [
        // {
        //   id: 1,
        //   receiver_name: 'Kritsana Uttamang',
        //   receiver_email: 'kritsana@hypcode.co',
        //   created_at: '2019-01-21T00:35:05.318363Z',
        //   status: 'joined',
        // },
        // {
        //   id: 2,
        //   receiver_name: 'Kritsana Uttamang',
        //   receiver_email: 'kritsana@hypcode.co',
        //   created_at: '2019-01-21T00:35:05.318363Z',
        //   status: 'sent',
        // },
      ]
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'OK',
      text: e,
      data: {
        invitations: [],
      },
    }
  }
}

// List Invitations
export async function listTransactionsAPI(eventsId, search) {
  let filter = ''
  if (search.keyword !== '') {
    if (search.keywordType === 'created_at') {
      filter = `?${search.keywordType}=${search.keyword.form}&from=${search.keyword.form
        }&to=${search.keyword.to}`
    } else {
      filter = `?${search.keywordType}=${search.keyword}`
    }
  }
  try {
    const data = await httpRequest(
      'GET',
      `${END_POINT}/events/${eventsId}/transactions${filter}`
    )
    data.data.transactions = data.data.transactions || []
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'OK',
      text: e,
      data: {
        transactions: [],
      },
    }
  }
}

// export Invitations file
export async function exportTransactionsAPI(eventsId, extension) {
  try {
    const data = await httpRequest(
      'GET',
      `${END_POINT}/invitations?event_id=${eventsId}&format=${extension}`,
      null,
      'blob'
    )
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// Post Blessing
export async function postBlessing(message, invitation_id) {
  try {
    const data = await httpRequest(
      'POST',
      `${END_POINT}/invitations/${invitation_id}/reply_msg`,
      { message }
    )
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// Check In
export async function postCheckIn(invitation_id) {
  try {
    const data = await httpRequest(
      'POST',
      `${END_POINT}/invitations/${invitation_id}/check-in`
    )
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// Replies Event
export async function getRepliesEvent(id, limit, offset) {
  try {
    const data = await httpRequest(
      'GET',
      `${END_POINT}/events/${id}/replies?limit=${limit}&offset=${offset}`
    )
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

const splitArrayToGrid = (data, gridColSize = 1) => {
  const _data = []
  if (data.length > 0) {
    const max_loop = Math.ceil(data.length / gridColSize)
    let lastIndex = -1
    for (let i = 1; i <= max_loop; i++) {
      const _data2 = []
      let maxStop = gridColSize
      // eslint-disable-next-line
      data.forEach((v, i2) => {
        if (i2 > lastIndex && maxStop > 0) {
          _data2.push(v)
          lastIndex = i2
          maxStop--
        }
      })
      _data.push(_data2)
    }
  }
  return _data
}

// Get Testimonial
export async function getTestimonials() {
  try {
    const { data } = await httpRequest('GET', `${END_POINT}/testimonials`)

    if (!data.testimonials) {
      data.testimonials = []
    } else {
      data.testimonials = splitArrayToGrid(data.testimonials, 4)
    }

    return {
      code: 'OK',
      text: null,
      data: data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// Update Profile User
export async function postUpdateProfileInfo(payload, header) {
  try {
    const data = await httpRequest(
      'POST',
      `${END_POINT}/profile/info`,
      payload,
      undefined,
      header
    )
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    let messageError = null

    return {
      code: 'FAIL',
      text: messageError ? messageError.error : e,
      data: null,
    }
  }
}
// Invite Club
export async function postInviteClub(payload, headers) {
  try {
    const data = await httpRequest(
      'POST',
      `${END_POINT}/club/${CLUB_KMITL}/member/invite`,
      payload,
      undefined,
      headers
    )
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    let messageError = null

    return {
      code: 'FAIL',
      text: messageError ? messageError.error : e,
      data: null,
    }
  }
}
// Reinvite club member in case of invitelink expried
export async function reinviteClubMember(clubID, payload, headers) {
  try {
    const data = await httpRequest(
      'POST',
      `${END_POINT}/club/${clubID}/member/reinvite`,
      payload,
      undefined,
      headers
    )
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    let messageError = null

    return {
      code: 'FAIL',
      text: messageError ? messageError.error : e,
      data: null,
    }
  }
}
// Join Club
export async function postJoinClub(payload, headers) {
  try {
    const data = await httpRequest(
      'POST',
      `${END_POINT}/club/${CLUB_KMITL}/member/save`,
      payload,
      undefined,
      headers
    )
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    let messageError = null

    return {
      code: 'FAIL',
      text: messageError ? messageError.error : e,
      data: null,
    }
  }
}
// Delete Member
export async function deleteUserInfo(header) {
  try {
    const data = await httpRequest(
      'DELETE',
      `${END_POINT}/club/${CLUB_KMITL}/member`,
      undefined,
      undefined,
      header
    )
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: null,
    }
  }
}

// List Events New
export async function getListEventsNew(category, filter = '') {
  try {
    const data = await httpRequest(
      'GET',
      `${END_POINT}/events?limit=30&offset=0&category=${category}${filter}`,
      null
    )
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    return {
      code: 'FAIL',
      text: e,
      data: { events: [] },
    }
  }
}

// searchMemberInClub
export async function searchMemberInClub(search = '', skip = 0, field) {
  try {
    const data = await httpRequest(
      'GET',
      `${END_POINT}/club/${CLUB_KMITL}/member/search?search=${search}&limit=10&skip=${skip}${field ? `&field=${field}` : ''
      }`,
      null
    )
    // console.log('searchMemberInClubNoAuth', data)
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    let messageError = null

    return {
      code: 'FAIL',
      text: messageError ? messageError.error : e,
      data: [],
    }
  }
}

// user send request to be club member

export async function sendRequestToClub(clubID, payload) {
  try {
    const data = await httpRequest(
      'POST',
      `${END_POINT}/club/${clubID}/member/requestToClub`,
      payload
    )
    console.log('sendRequestToClub', data)
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    let messageError = null

    return {
      code: 'FAIL',
      text: messageError ? messageError.error : e,
      data: [],
    }
  }
}

// Get list of users request to be a member

export async function requestToClub(clubID) {
  try {
    const data = await httpRequest(
      'GET',
      `${END_POINT}/club/${clubID}/member/requestToClub`,
      null
    )
    //console.log('requestToClub', data)
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    let messageError = null

    return {
      code: 'FAIL',
      text: messageError ? messageError.error : e,
      data: [],
    }
  }
}

// Approve or reject a user that request to be a clubmember

export async function requestToClubAction(clubID, payload) {
  try {
    const data = await httpRequest(
      'PUT',
      `${END_POINT}/club/${clubID}/member/requestToClub`,
      payload
    )
    console.log('requestToClubAction', data)
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    let messageError = null

    return {
      code: 'FAIL',
      text: messageError ? messageError.error : e,
      data: [],
    }
  }
}


export async function createUserProfileInfo(payload) {
  try {
    const data = await httpRequest(
      'POST',
      `https://api.invitree.me/v1/api/profile/create`,
      payload
    )
    // console.log('searchMemberInClubNoAuth', data)
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    let messageError = null

    return {
      code: 'FAIL',
      text: messageError ? messageError.error : e,
      data: [],
    }
  }
}

// ListMemberInClub
export async function listMemberInClub(skip = 0) {
  try {
    const data = await httpRequest(
      'GET',
      `${END_POINT}/club/${CLUB_KMITL}/member?limit=10&skip=${skip}`
    )
    return {
      code: 'OK',
      text: null,
      data: data.data || [],
    }
  } catch (e) {
    let messageError = null

    return {
      code: 'FAIL',
      text: messageError ? messageError.error : e,
      data: null,
    }
  }
}

export async function listDataPersonalInfo() {
  try {
    const data = await httpRequest(
      'GET',
      `${END_POINT}/invitree/master-data/profile-info`
    )
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    let messageError = null

    return {
      code: 'FAIL',
      text: messageError ? messageError.error : e,
      data: null,
    }
  }
}

// Get Profile Check
export async function checkProfileInfo(payload) {
  try {
    const data = await httpRequest(
      'POST',
      `${END_POINT}/profile/get-info`,
      payload
    )
    // console.log('data:', data)

    return {
      code: 'OK',
      status: data.status,
      text: null,
      data: data.data,
    }
  } catch (e) {
    let messageError = null

    return {
      code: 'FAIL',
      text: messageError ? messageError.error : e,
      data: null,
    }
  }
}

// upload profile image student
export async function uploadProfileImg(payload) {
  try {
    const data = await httpRequest('POST', `${END_POINT}/upload`, payload)
    // console.log('data:', data)

    return {
      code: 'OK',
      status: data.status,
      text: null,
      data: data.data,
    }
  } catch (e) {
    let messageError = null

    return {
      code: 'FAIL',
      text: messageError ? messageError.error : e,
      data: null,
    }
  }
}

export async function getProfileFromId(id) {
  try {
    const data = await httpRequest('GET', `${END_POINT}/profile/info/${id}`)
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    let messageError = null

    return {
      code: 'FAIL',
      text: messageError ? messageError.error : e,
      data: null,
    }
  }
}

export async function getPositionFromId(id) {
  try {
    const data = await httpRequest(
      'GET',
      `${END_POINT}/club/${CLUB_KMITL}/member/info/${id}`
    )
    return {
      code: 'OK',
      text: null,
      data: data.data,
    }
  } catch (e) {
    let messageError = null

    return {
      code: 'FAIL',
      text: messageError ? messageError.error : e,
      data: null,
    }
  }
}
