const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  SIGN_IN_REQUEST: 'SIGN_IN_REQUEST',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_ERROR: 'SIGN_IN_ERROR',
  SIGN_OUT_REQUEST: 'SIGN_OUT_REQUEST',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_ERROR: 'SIGN_OUT_ERROR',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  COMPLETE_NEW_PASSWORD: 'COMPLETE_NEW_PASSWORD',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  CHECK_USER_PDPA: 'CHECK_USER_PDPA',
  SET_CHALLENGE_USER: 'SET_CHALLENGE_USER',
  HANDLE_ERROR: 'HANDLE_ERROR',
  checkAuthorization: (
    authorizedRedirect,
    unauthorizedRedirect,
    authorizedAction,
    options
  ) => ({
    type: actions.CHECK_AUTHORIZATION,
    authorizedRedirect: authorizedRedirect,
    unauthorizedRedirect: unauthorizedRedirect,
    authorizedAction: authorizedAction,
    options,
  }),
  signIn: (username, password) => ({
    type: actions.SIGN_IN_REQUEST,
    username,
    password,
  }),
  signOut: () => ({
    type: actions.SIGN_OUT_REQUEST,
  }),
  changePassword: (user, oldPassword, newPassword) => ({
    type: actions.CHANGE_PASSWORD,
    user,
    oldPassword,
    newPassword,
  }),
  completeNewPassword: (user, newPassword) => ({
    type: actions.COMPLETE_NEW_PASSWORD,
    user,
    newPassword,
  }),
  forgotPassword: (username, fn) => ({
    type: actions.FORGOT_PASSWORD,
    username,
    fn,
  }),
  resetPassword: (username, code, newPassword, fn) => ({
    type: actions.RESET_PASSWORD,
    username,
    code,
    newPassword,
    fn,
  }),
  setCurrentUser: user => ({
    type: actions.SET_CURRENT_USER,
    user,
  }),
  checkUserPdpa: pdpaverified => ({
    type: actions.CHECK_USER_PDPA,
    pdpaverified,
  }),
  handleError: err => ({
    type: actions.HANDLE_ERROR,
    error: err,
  }),
  OAUTH_LOGIN: 'AUTH_OAUTH_LOGIN',
  onOAuthLogin: access_token => ({
    type: actions.OAUTH_LOGIN,
    access_token,
  }),
}

export default actions
