import actions from './actions'

const initState = {
  key: '',
}

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.MENU_SELECTED:
      return { ...state, key: action.key }
    default:
      return state
  }
}
