import Cookie from 'js-cookie'
import actions from './actions'
import langEN from '../../locale/en'
import langTH from '../../locale/th'

const initialState = {
  isOpen: true,
  isValid: true,
  dateCriteria: { from: '', to: '' },
  columnCriteria: { colName: 'tx_group_id', colValue: '' },
  windowSize: { width: 0, height: 0 },
  packageForUpgrade: [],
  lang: Cookie.get('LANG') || 'TH',
  locale: {
    EN: { ...langEN },
    TH: { ...langTH },
  },
  tempLocale: '/',
}

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case actions.TOOGLE_SEARCH_CRITERIA:
      return { ...state, isOpen: action.payload }
    case actions.VALIDATE_SEARCH_CRITERIA:
      return { ...state, isValid: action.payload }
    case actions.UPDATE_DATE_RANGE_CRITERIA:
      return { ...state, dateCriteria: action.payload }
    case actions.UPDATE_COLUMN_RANGE_CRITERIA:
      return { ...state, columnCriteria: action.payload }
    case actions.UPDATE_WINDOW_SIZE:
      return { ...state, windowSize: action.payload }
    case actions.TOGGLE_DROPDOWN_INPUT:
      return { ...state, dropdownVisible: action.payload }
    case actions.SET_PACKAGE_LIST_UPGRADE:
      return { ...state, packageForUpgrade: action.payload }
    case actions.SET_LANGUAGE:
      Cookie.set('LANG', action.payload.lang)
      return {
        ...state,
        lang: action.payload.lang,
        tempLocale: action.payload.tempLocale,
      }
    default:
      return state
  }
}
