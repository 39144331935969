const actions = {
  SET_TRANSACTION_DETAIL: 'SET_TRANSACTION_DETAIL_POPUP',
  SET_LIST_TRANSACTION: 'SET_LIST_TRANSACTION',

  ON_LIST_TRANSACTION: 'ON_LIST_TRANSACTION',
  listTransaction: payload => ({
    type: actions.ON_LIST_TRANSACTION,
    payload,
  }),

  ON_SHOW_TRANSACTION_DETAIL: 'ON_SHOW_TRANSACTION_DETAIL',
  onShowTransactionDetail: payload => ({
    type: actions.ON_SHOW_TRANSACTION_DETAIL,
    payload,
  }),
}

export default actions
